import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import MyEditor from './MyEditor';
import axios from "axios";
// import './button.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ObjectiveModal = ({ type, data, onClose, onSave, Ip }) => {
  const [description, setDescription] = useState(data ? data.description : "");
  const [error, setError] = useState("");

  const handleAdd = async () => {
    if (!description) {
      setError("Please enter all required fields!");
      return;
    }
    setError("");

    try {
      const formData = new FormData();
      formData.append("description", description);
      formData.append("Action", "Add");

      const response = await axios.post(
        `${Ip}Controllers/ourobjective.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Objective saved:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error saving Objective:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save Objective");
    }

    onClose();
  };

  const handleEdit = async () => {
    if (!description) {
      setError("Please enter all required fields!");
      return;
    }
    setError("");
    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("description", description);
      formData.append("Action", "Edit");

      const response = await axios.post(
        `${Ip}Controllers/ourobjective.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Vision Mission updated:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error updating Vision Mission:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save news");
    }

    onClose();
  };
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ header: [1, 2, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
      ['clean'],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];
  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "Add" ? "Add" : type === "Edit" ? "Edit" : "View"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Description</Form.Label>
            {/* <Form.Control
              as="textarea"
              rows={2}
              placeholder="Add description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              readOnly={type === "View"}
            /> */}
            <ReactQuill
              value={description}
              onChange={(value) => setDescription(value)}
              modules={modules}
              formats={formats}
              placeholder="Add description"
              theme="snow"
              readOnly={type === "View"}
          />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {(type === "Add" || type === "Edit") && (
          <Button
            variant="primary"
            style={{ backgroundColor: "#0120ff", borderColor: "#0120ff" }}
            onClick={type === "Add" ? handleAdd : handleEdit}
          >
            {type === "Add" ? "Add" : "Save Changes"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ObjectiveModal;
