import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HighlightsModal from '../Modals/HighlightsModal';
import DataTable from 'react-data-table-component'; // Ensure you have this package installed
import '../sidebar.css';
import './pages.css';
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import Swal from 'sweetalert2';

const HighlightsDash = ({ title, Ip,isSuper,isEditor }) => {
  const [Highlights, setSubHighlights] = useState([]);
  const [filteredHighlights, setFilteredHighlights] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchHighlights();
  }, []);

  useEffect(() => {
    // Filter highlights whenever the search term changes
    setFilteredHighlights(
      Highlights.filter(highlight => 
        highlight.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        highlight.link.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, Highlights]);

  const fetchHighlights = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/highlights.php`, {
        params: { 'Action': 'Fetch' },
        headers: { 'Content-Type': 'application/json' }
      });
      setSubHighlights(response.data);
      setFilteredHighlights(response.data);
    } catch (error) {
      console.error('Error fetching Highlights:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, highlight = null) => {
    setModalType(type);
    setModalData(highlight);
    setShowModal(true);
  };

  const handleDeleteHighlight = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Action', 'Delete');

        await axios.post(`${Ip}Controllers/highlights.php`, formData);
        fetchHighlights();

        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } else {
        Swal.fire('Cancelled', 'Your item is safe :)', 'error');
      }
    } catch (error) {
      console.error('Error deleting Highlight:', error.response ? error.response.data : error.message);
      Swal.fire('Error!', 'There was an error deleting the highlight item.', 'error');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const columns = [
    {
      name: 'Title',
      selector: row =>(
        <div dangerouslySetInnerHTML={{ __html: row.title }} />
      ),  
      sortable: true,
    },
    {
      name: 'Image',
      cell: row => {
        const imgPathFromDatabase = row.img;
        let correctedImagePath;

        if (imgPathFromDatabase.includes('../../../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../../../assets', 'assets');
        } else if (imgPathFromDatabase.includes('../../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../../assets', 'assets');
        } else if (imgPathFromDatabase.includes('../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../assets', 'assets');
        } else {
          correctedImagePath = imgPathFromDatabase; 
        }

        const image = `${Ip}${correctedImagePath}`;
        return <img src={image} alt={row.title} style={{ width: '30%' }} />;
      },
    },
    {
      name: 'Link to',
      selector: row => row.link,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="button-group">
          <button className="btn section_btn" title='View' onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          <button className="btn section_btn" title='Edit' onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
            <FaEdit />
          </button>
          {(isSuper==='true') && (<button className="btn section_btn" title='Delete' onClick={() => handleDeleteHighlight(row.id)} style={{ padding: '5px' }}>
            <FaDeleteLeft />
          </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className='d-flex flex-row align-items-center justify-content-between gap-4' style={{ width: '75vw' }}>
        <div className="px-5" style={{ width: '100%', paddingTop: '3em', paddingBottom: '0' }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          />
        </div>
        <div className="addBtn">
          <button
            style={{
              backgroundColor: '#0120ff',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '30px',
              width: '150px',
              marginTop: '3em'
            }}
            onClick={() => handleModalOpen('Add')}
          >
            Add
          </button>
        </div>
      </div>
      <div className="lht_table table table-hover" style={{ width: '90vw', paddingTop: '30px' }}>
        <DataTable
          columns={columns}
          data={filteredHighlights}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: 'flex-end',
              },
            },
          }}
        />
      </div>

      {showModal && (
        <HighlightsModal 
          Ip = {Ip}
          type={modalType} 
          data={modalData} 
          onClose={handleCloseModal} 
          onSave={() => {
            handleCloseModal();
            fetchHighlights();
          }} 
        />
      )}
    </div>
  );
};

export default HighlightsDash;
