import {React,useEffect,useState} from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventYearArray from './EventYearArray';
import { FaPlay } from "react-icons/fa";
import './EventYear.css'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div 
            className='rounded-circle d-flex flex-row align-items-center justify-content-center position-absolute end-0 ' 
            style={{width: '60px', height: '60px', transform: 'translate(29%, -21rem)', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255,0.5)'}}
        >
            <div 
                className='rounded-circle bg_primary d-flex flex-row align-items-center justify-content-center' 
                style={{width: '35px', height: '35px'}}
                onClick={onClick}
            >
                <FaPlay size='15' fill='#fff'/>
            </div>
        </div>
    );
}

const settings = {
    easing: 'linear',
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
};

const EventYear = ({Ip}) => {
    const [events,setEvents]=useState([]);
    useEffect(() => {
        fetchEvents();
      }, []);
    
      const fetchEvents = async () => {
        try {
            const response = await axios.get(`${Ip}Controllers/events.php`, {
                params: { Action: 'youcat' } 
            });
            // console.log("Fetched Events:", response.data);
            // console.log("Is array:", Array.isArray(response.data));
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching Events:', error.response ? error.response.data : error.message);
        }
    };
    
    return (
        <div className='EventYear_container' style={{marginTop: '5rem'}}>
            <div className='container mb-4'>
                <h5 className='text-start text-uppercase'>events of the year</h5>
            </div>
            <div className="container">
                <Slider {...settings}>
                    {events.map((event, index) => {
                        const img = event.img;
                        let imagPath = img;
                        if (imagPath.startsWith("../../../assets")) {
                          imagPath = img.replace("../../../assets", "assets");
                        }else if (imagPath.startsWith("../../assets")) {
                            imagPath = img.replace("../../assets", "assets");
                        } else if (imagPath.startsWith("../assets")) {
                        imagPath = img.replace("../assets", "assets");
                        }
                        const correctedImagePath = `${Ip}${imagPath}`;
                        const multipleImages = JSON.parse(event.multiple_img || '[]');
                        const imgCount = multipleImages.length;
                        return (
                        <div className="container my-6" key={index}>
                            <div className='EventYearBg position-relative' style={{backgroundImage: `url(${correctedImagePath})`}}>
                                <div className='bottom-content'>
                                    <div className="text-start text-white container py-2">
                                    {imgCount >= 2 ? (
                                            <p className='fw-bold m-0'>{imgCount} Items</p>
                                        ) : (
                                            <p className='fw-bold m-0'>{imgCount} Item</p>
                                        )}
                                        <h5 className='fw-bold'>{event.title}</h5>
                                        <p  style={{fontSize: '0.8rem'}}>{event.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        );    
                })}
                </Slider>
            </div>
        </div>
    );
};

export default EventYear;
