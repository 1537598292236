import React, { useState, useEffect } from "react";
import axios from "axios";
import OurStroyModal from "../Modals/OurStoryModal";
import "../sidebar.css";
import "./pages.css";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { FaDeleteLeft } from "react-icons/fa6";
import Swal from "sweetalert2";

const OurStoryDash = ({ title, Ip,isSuper,isEditor }) => {
  const [OurStory, setOurStory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchOurStory();
  }, []);

  const fetchOurStory = async () => {
    try {
      const response = await axios.get(
        `${Ip}Controllers/ourstory.php`,
        {
          params: {
            Action: "Fetch",
          },
          headers: { "Content-Type": "application/json" },
        }
      );
      setOurStory(response.data);
    } catch (error) {
      console.error(
        "Error fetching Highlights:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleModalOpen = (type, heroes = null) => {
    setModalType(type);
    setModalData(heroes);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType("");
    setModalData(null);
  };
  const filteredHeroes = OurStory.filter((story) => {
    return (
      story.description1.toLowerCase().includes(searchTerm.toLowerCase()) ||
      story.description2.toLowerCase().includes(searchTerm.toLowerCase()) ||
      story.description3.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const columns = [
    {
      name: "Description 1",
      selector: (row) => (row.description1 ? row.description1.substring(0, 20) + " ..." : "No Description"),
      sortable: true,
    },
    {
      name: "Description 2",
      selector: (row) => (row.description2 ? row.description2.substring(0, 20) + " ..." : "No Description"),
      sortable: true,
    },
    {
      name: "Description 3",
      selector: (row) => (row.description3 ? row.description3.substring(0, 20) + " ..." : "No Description"),
      sortable: true,
    },
    {
      name: "Images",
      cell: (row) => {
        let images = [];
  
        try {
          images = JSON.parse(row.img);
          if (!Array.isArray(images)) {
            throw new Error("Parsed JSON is not an array");
          }
        } catch (e) {
          console.error("Error parsing images JSON:", e);
          images = [];
        }
  
        return (
          <div style={{ display: "flex", gap: "20px" }}>
            {images.map((ima, idx) => {
              let imagPath = ima.img;
              
              if (imagPath.startsWith("../../../assets")) {
                imagPath = imagPath.replace("../../../assets", "assets");
              } else if (imagPath.startsWith("../../assets")) {
                imagPath = imagPath.replace("../../assets", "assets");
              } else if (imagPath.startsWith("../assets")) {
                imagPath = imagPath.replace("../assets", "assets");
              }
  
              const correctedImagePath = `${Ip}${imagPath}`;
  
              return (
                <img
                  key={idx} 
                  src={correctedImagePath}
                  alt={`Img ${idx}`}
                  style={{ width: "27%" }}
                />
              );
            })}
          </div>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
      <div className="d-flex flex-row align-items-center button-group">
      <button className="btn section_btn" title='View' onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
        <FaEye />
      </button>
      <button className="btn section_btn" title='Edit' onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
        <FaEdit />
      </button>
      </div>
      )
  },
  ];

  return (
    // <div className="news-page">
    //   <div>
    //     <h1 style={{ textAlign:'left',fontSize: '25px', fontWeight: 'bold', marginLeft: '10px'}}>{title}</h1>
    //   </div>
    //   <div className="lht_table">
    //     <table className="table table-hover" style={{ tableLayout: 'fixed',textAlign:'left'}}>
    //       <thead>
    //         <tr>
    //           <th style={{ width: '10%',textAlign:'left'}}>Description 1</th>
    //           <th style={{ width: '10%',textAlign:'left'}}>Description 2</th>
    //           <th style={{ width: '10%',textAlign:'left'}}>Description 3</th>
    //           <th style={{ width: '10%',textAlign:'left'}}>Image 1</th>
    //           <th style={{ width: '10%',textAlign:'left'}}>Image 2</th>
    //           <th style={{ width: '10%',textAlign:'left'}}>Image 3</th>
    //           {/* <th style={{ width: '10%',textAlign:'left'}}>Button</th> */}
    //           <th style={{ width: '30%',textAlign:'left'}}>Actions</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {OurStory.length ? (
    //             OurStory.map(life => {
    //                 let images = [];
    //                 try {
    //                     images = JSON.parse(life.img);
    //                     if (!Array.isArray(images)) {
    //                     throw new Error("Parsed JSON is not an array");
    //                     }
    //                 } catch (e) {
    //                     console.error("Error parsing images JSON:", e);
    //                     images = [];
    //                 }
    //               return (
    //                 <tr key={life.id}>
    //                 <td>{life.description1.substring(0,20)+' ...'}</td>
    //                 <td>{life.description2.substring(0,20)+' ...'}</td>
    //                 <td>{life.description3.substring(0,20)+' ...'}</td>
    //                 {images.map((ima, idx) => {
    //                     const img = ima.img;
    //                     let imagPath = img;
    //                     if (imagPath.startsWith("../../../assets")) {
    //                         imagPath = img.replace("../../assets", "assets");
    //                     } else if (imagPath.startsWith("../assets")) {
    //                         imagPath = img.replace("../assets", "assets");
    //                     }

    //                     const correctedImagePath = `http://localhost/bkerkeyouth/src/admin/${imagPath}`;

    //                     return (
    //                         <td key={idx}>
    //                             <img src={correctedImagePath} alt={`Image ${idx}`} style={{width:'50%'}}/>
    //                         </td>
    //                     );
    //                 })}
    //                 {/* <td dangerouslySetInnerHTML={{ __html: life.description.substring(0, 200)}}></td> */}
    //                 <td className="button-group">
    //                   <button className="btn section_btn" onClick={() => handleModalOpen('View',life)} style={{padding:'5px'}}>
    //                     <FaEye/>
    //                   </button>
    //                   <button className="btn section_btn" onClick={() => handleModalOpen('Edit', life)} style={{padding:'5px'}}>
    //                     <FaEdit/>
    //                   </button>
    //                   {/* <button className="btn section_btn" onClick={() =>handleDeleteHero(life.id)} style={{padding:'5px'}}>
    //                     <FaDeleteLeft/>
    //                   </button> */}
    //                 </td>
    //               </tr>
    //             );
    //           })
    //         ) : (
    //           <tr><td colSpan="5">No data available</td></tr>
    //         )}
    //       </tbody>
    //     </table>
    //   </div>
    <div className="news-page">
      <div>
        <h1
          style={{
            textAlign: "left",
            fontSize: "25px",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          {title}
        </h1>
      </div>
      <div
        className="d-flex flex-row align-items-center justify-content-between gap-4"
        style={{ width: "75vw" }}
      >
      </div>

      <div
        className="lht_table table table-hover"
        style={{ width: "85vw", paddingTop: "30px" }}
      >
        <DataTable
          columns={columns}
          data={OurStory}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: "flex-end",
              },
            },
          }}
        />
      </div>
      {showModal && (
        <OurStroyModal
          Ip = {Ip}
          type={modalType}
          data={modalData}
          onClose={handleCloseModal}
          onSave={() => {
            handleCloseModal();
            fetchOurStory();
          }}
        />
      )}
    </div>
  );
};

export default OurStoryDash;
