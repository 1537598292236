  import React, { useState, useEffect } from "react";
  import { Modal, Button, Form } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  // import MyEditor from './MyEditor';
  import ReactQuill, { Quill } from "react-quill";
  import "react-quill/dist/quill.snow.css";
  import axios from "axios";
  // import './button.css';

  const Parchment = Quill.import('parchment');
  const LineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['1', '1.5', '2', '2.5', '3'] 
  });
  Quill.register(LineHeightStyle, true);

  const HeroModal = ({ type, data, onClose, onSave, Ip }) => {
    const [title, setTitle] = useState(data ? data.title : "");
    const [date, setDate] = useState(data ? data.date : "");
    const [img, setImg] = useState(null);
    const [subTitle, setSubTitle] = useState(data ? data.subtitle : "");
    const [description, setDescription] = useState(data ? data.description : "");
    const [image, setImage] = useState(null);
    const [error, setError] = useState("");
    //   console.log('Incoming data for HeroModal:', data);
    //   console.log('Current button state:', data.button);
    let buttons = [];
    try {
      if (data && data.button) {
        buttons = JSON.parse(data.button);
      } else {
        buttons = [{ text: "", link: "/admin" }];
      }
    } catch (e) {
      console.error("Error parsing button JSON:", e);
    }
    const [buttons1, setButtons1] = useState(buttons);

    const handleTextChange = (e, index) => {
      const newButtons = [...buttons];
      newButtons[index].text = e.target.value;
      setButtons1(newButtons);
      // sendUpdatedButtons(newButtons);
    };

    //   const sendUpdatedButtons = (updatedButtons) => {
    //     const formData = new FormData();
    //     formData.append('buttons', JSON.stringify(updatedButtons));
    //   }

    const handleAdd = async () => {
      if (!title || !description) {
        setError("Please enter all required fields!");
        return;
      }
      setError("");

      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("date", date);
        formData.append("subTitle", subTitle);
        formData.append("description", description);
        formData.append("img", image);
        formData.append("buttons", JSON.stringify(buttons1));
        formData.append("Action", "Add");

        const response = await axios.post(
          `${Ip}Controllers/hero.php`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        console.log("hero saved:", response.data);
        onSave();
      } catch (error) {
        console.error(
          "Error saving hero:",
          error.response ? error.response.data : error.message
        );
        setError("Failed to save hero");
      }

      onClose();
    };

    const handleEdit = async () => {
      if (!title || !description) {
        setError("Please enter all required fields!");
        return;
      }
      setError("");

      try {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("title", title);
        formData.append("subTitle", subTitle);
        formData.append("description", description);
        formData.append("img", image);
        formData.append("buttons", JSON.stringify(buttons1));
        formData.append("Action", "Edit");

        const response = await axios.post(
          `${Ip}Controllers/hero.php`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        console.log("Hero updated:", response.data);
        onSave();
      } catch (error) {
        console.error(
          "Error updating Hero:",
          error.response ? error.response.data : error.message
        );
        setError("Failed to save news");
      }

      onClose();
    };
    // const handleView = async () => {
    //   try {
    //     const formData = new FormData();
    //     formData.append('id', data.id);
    //     formData.append('Action','View')

    //     const response = await axios.post('http://localhost/patriarchdouaihy/src/admin/Controllers/steps.php', formData, {
    //       headers: { 'Content-Type': 'multipart/form-data' }
    //     });
    //     console.log('Success:', response.data);
    //     onSave();
    //   } catch (error) {
    //     console.error('Error :', error.response ? error.response.data : error.message);
    //     setError('Failed to view');
    //   }

    //   onClose();
    // };

    const modules = {
      toolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ 'lineheight': ['1', '1.5', '2', '2.5', '3'] }],
        ["bold", "italic", "underline", "strike"]
      ]
    };

    const formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "color",
      "lineheight"
    ];

    return (
      <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "Add" ? "Add" : type === "Edit" ? "Edit" : "View"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                readOnly={type === "View"}
              />
            </Form.Group> */}
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              {/* {type === 'View' ? (
              <Form.Control 
                type="text" 
                value={title} 
                readOnly 
              />
            ) : ( */}
            <ReactQuill
              value={title}  // Or whichever value
              onChange={(value) => setTitle(value)}
              modules={modules}
              formats={formats}
              placeholder="Enter the title"
              theme="snow"
              readOnly={type === "View"}
            />
              {/* )} */}
            </Form.Group>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter the date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                readOnly={type === "View"}
              />
            </Form.Group>

            <Form.Group controlId="formTitle">
              <Form.Label>SubTitle</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the Subtitle"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
                readOnly={type === "View"}
              />
            </Form.Group>
            <Form.Group controlId="formTitle">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter the description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                readOnly={type === "View"}
              />
            </Form.Group>

            {buttons1.map((btn, index) => (
              <div key={index}>
                <Form.Group controlId={`formTitle-${index}`}>
                  <Form.Label>Button Text {index + 1}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the button title"
                    value={btn.text || ""}
                    readOnly={type === "View"}
                    onChange={(e) => handleTextChange(e, index)}
                  />
                </Form.Group>

                {/* <Form.Group controlId={`formLink-${index}`}>
              <Form.Label>Button Link {index + 1}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the button link"
                value={btn.link || ''}
                onChange={(e) => handleLinkChange(e, index)} 
              />
            </Form.Group> */}
              </div>
            ))}

            {/* <Form.Group controlId="formDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Enter the description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group> */}
            {/* <Form.Group controlId="formDescription" className="mt-3 border-box">
              <Form.Label>Description</Form.Label>
              <div
                style={{
                  maxHeight: '200px', 
                  overflowY: 'auto',
                  border: '1px solid #ccc', 
                  padding: '10px',    
                  borderRadius: '5px', 
                }}
              >
                <MyEditor
                  value={description}             
                  onChange={setDescription}     
                  placeholder="Enter the description" 
                />
              </div>
            </Form.Group> */}

            {(type === "Add" || type === "Edit") && (
              <Form.Group controlId="formImage" className="mt-3">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Form.Group>
            )}
            {error && <div className="text-danger mt-2">{error}</div>}
            {type === "View" && (
              <Form.Group controlId="formDescription" className="mt-3">
                <Form.Label>Image</Form.Label>
                {(() => {
                  let correctedImagePath;
                  if (data.bg_img.includes("../../assets")) {
                    correctedImagePath = data.bg_img.replace(
                      "../../assets",
                      "assets"
                    );
                  } else if (data.bg_img.includes("../assets")) {
                    correctedImagePath = data.bg_img.replace(
                      "../assets",
                      "assets"
                    );
                  } else {
                    correctedImagePath = data.bg_img;
                  }
                  const image = `${Ip}${correctedImagePath}`;
                  return (
                    <div>
                      <img
                        src={image}
                        alt={data.title}
                        style={{ maxWidth: "20%", height: "auto" }}
                      />
                    </div>
                  );
                })()}
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          {(type === "Add" || type === "Edit") && (
            <Button
              variant="primary"
              style={{ backgroundColor: "#0120ff", borderColor: "#0120ff" }}
              onClick={type === "Add" ? handleAdd : handleEdit}
            >
              {type === "Add" ? "Add" : "Save Changes"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  export default HeroModal;
