import React, { useState, useEffect } from 'react';
import axios from 'axios';
import VMModal from '../Modals/VMModal'; 
import '../sidebar.css'; 
import './pages.css';
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import DataTable from 'react-data-table-component'; 
import DOMPurify from 'dompurify'; 

const VisionMissionDash = ({ title, Ip }) => {
  const [SubBooks, setSubBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchBookHighlights();
  }, []);

  useEffect(() => {
    setFilteredBooks(
      SubBooks.filter(book => 
        book.vision.toLowerCase().includes(searchTerm.toLowerCase()) ||
        book.mission.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, SubBooks]);

  const fetchBookHighlights = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/visionmission.php`, {
        params: {
          'Action': 'Fetch'
        },
        headers: { 'Content-Type': 'application/json' }
      });
      setSubBooks(response.data);
      setFilteredBooks(response.data);
    } catch (error) {
      console.error('Error fetching Highlights:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, heroes = null) => {
    setModalType(type);
    setModalData(heroes);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const columns = [
    {
      name: 'Vision',
      selector: row => row.vision,
      cell: row => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.vision.substring(0, 70) + ' ...') }} />,
      sortable: true,
    },
    {
      name: 'Mission',
      selector: row => row.mission,
      cell: row => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.mission.substring(0, 70) + ' ...') }} />,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="button-group">
          <button className="btn section_btn" title='View' onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          <button className="btn section_btn" title='Edit' onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
            <FaEdit />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className='d-flex flex-row align-items-center justify-content-between gap-4' style={{ width: '75vw' }}>
        <div className="px-5" style={{ width: '100%', paddingTop: '3em', paddingBottom: '0' }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          />
        </div>
      </div>
      <div className="lht_table" style={{ width: '90vw', paddingTop: '30px' }}>
        <DataTable 
          columns={columns}
          data={filteredBooks}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: 'flex-end',
              },
            },
          }}
        />
      </div>

      {showModal && (
        <VMModal 
          Ip = {Ip}
          type={modalType} 
          data={modalData} 
          onClose={handleCloseModal} 
          onSave={() => {
            handleCloseModal();
            fetchBookHighlights();
          }} 
        />
      )}
    </div>
  );
};

export default VisionMissionDash;
