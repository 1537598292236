// src/screens/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../NotFound.css'; // Import CSS for styling

const NotFoundPage = () => {
  return (
    <div className="not-found d-flex flex-column align-items-center justify-content-center" style={{height: '100vh'}}>
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className="not-found-link btn_primary">Go to Home</Link>
    </div>
  );
}

export default NotFoundPage;
