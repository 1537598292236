import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UsersModal from '../Modals/UsersModal'; 
import '../sidebar.css'; 
import './pages.css';
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { FaDeleteLeft } from "react-icons/fa6";
import Swal from 'sweetalert2';

const UsersDash = ({ title, Ip,isSuper,isEditor}) => {
  const [Users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/users.php`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, user = null) => {
    setModalType(type);
    setModalData(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const handleDeleteUser = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Action', 'Delete');

        await axios.post(`${Ip}Controllers/users.php`, formData);
        fetchUsers();

        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } else {
        Swal.fire('Cancelled', 'Your item is safe :)', 'error');
      }
    } catch (error) {
      console.error('Error deleting user:', error.response ? error.response.data : error.message);
      Swal.fire('Error!', 'There was an error deleting the user.', 'error');
    }
  };

  const filteredUsers = Users.filter(user => {
    return (
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const columns = [
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Role',
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex flex-row align-items-center button-group">
          <button className="btn section_btn" title='View' onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          <button className="btn section_btn" title='Edit' onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
            <FaEdit />
          </button>
          {(isSuper==='true') && (<button className="btn section_btn" title='Delete' onClick={() => handleDeleteUser(row.id)} style={{ padding: '5px' }}>
            <FaDeleteLeft />
          </button>
          )}
        </div>
      )
    }
  ];

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between gap-4" style={{ width: "75vw" }}>
        <div className="px-5" style={{ width: "100%", paddingTop: "3em", paddingBottom: "0" }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <button
          style={{
            backgroundColor: '#0120ff',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            textAlign: 'center',
            textDecoration: 'none',
            fontSize: '16px',
            borderRadius: '30px',
            width: '150px',
          }}
          onClick={() => handleModalOpen('Add')}
        >
          Add
        </button>
      </div>

      <div className="lht_table" style={{ width: "85vw", paddingTop: "30px" }}>
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: "flex-end",
              },
            },
          }}
        />
      </div>
      {showModal && (
        <UsersModal
          Ip = {Ip}
          type={modalType}
          data={modalData}
          onClose={handleCloseModal}
          onSave={() => {
            handleCloseModal();
            fetchUsers();
          }}
        />
      )}
    </div>
  );
};

export default UsersDash;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import UsersModal from '../Modals/UsersModal'; 
// import '../sidebar.css'; 
// import './pages.css';
// import { FaEye } from "react-icons/fa";
// import { FaEdit } from "react-icons/fa";
// import { FaDeleteLeft } from "react-icons/fa6";
// import Swal from 'sweetalert2';

// const UsersDash = ({ title }) => {
//   const [Users, setUsers] = useState([]);
//   const [modalData, setModalData] = useState(null);
//   const [modalType, setModalType] = useState('');
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     fetchUsers()
// ;  }, []);

//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get('http://localhost/bkerkeyouth/src/admin/Controllers/users.php', {
//         headers: { 'Content-Type': 'application/json' }
//       });
//       setUsers(response.data);
//     //   console.log(response.data);
//     } catch (error) {
//       console.error('Error fetching Highlights:', error.response ? error.response.data : error.message);
//     }
//   };

//   const handleModalOpen = (type, heroes = null) => {
//     setModalType(type);
//     setModalData(heroes);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setModalType('');
//     setModalData(null);
//   };
//   const handleDeleteHero = async (id) => {
//     try {
//       const result = await Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!"
//       });
  
//       if (result.isConfirmed) {
//         const formData = new FormData();
//         formData.append('id', id);
//         formData.append('Action', 'Delete');
  
//         await axios.post('http://localhost/bkerkeyouth/src/admin/Controllers/users.php', formData);
//         fetchUsers();

//         Swal.fire(
//           'Deleted!',
//           'Your item has been deleted.',
//           'success'
//         );
//       } else {
//         Swal.fire(
//           'Cancelled',
//           'Your item is safe :)',
//           'error'
//         );
//       }
//     } catch (error) {
//       console.error('Error deleting users:', error.response ? error.response.data : error.message);
//       Swal.fire(
//         'Error!',
//         'There was an error deleting the users item.',
//         'error'
//       );
//     }
//   };
  
//   const isSuper = localStorage.getItem('isSuper');
//   const isEditor = localStorage.getItem('isEditor');
//   return (
//     <div className="news-page">
//       <div>
//         <h1 style={{ textAlign:'left',fontSize: '25px', fontWeight: 'bold', marginLeft: '10px'}}>{title}</h1>
//       </div>
//       <div className="my-3 addBtn" style={{ position: 'relative', width: '80%' }}>
//       <button
//         style={{
//           // position: 'absolute',  
//           backgroundColor: '#0120ff',
//           color: 'white',
//           border: 'none',
//           padding: '10px 20px',
//           textAlign: 'center',
//           textDecoration: 'none',
//           fontSize: '16px',
//           borderRadius: '30px',
//           width: '150px',
//           marginLeft:'90%', 
          
//         }}
//         onClick={() => handleModalOpen('Add')}
//       >
//         Add
//       </button>
//       </div>
//       <div className="lht_table">
//         <table className="table table-hover" style={{ tableLayout: 'fixed',textAlign:'left'}}>
//           <thead>
//             <tr>
//               <th style={{ width: '20%',textAlign:'left'}}>Username</th>
//               <th style={{ width: '20%',textAlign:'left'}}>Email</th>
//               <th style={{ width: '20%',textAlign:'left'}}>Role</th>
//               {/* <th style={{ width: '10%',textAlign:'left'}}>Button</th> */}
//               <th style={{ width: '25%',textAlign:'left'}}>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Users.length ? (
//                 Users.map(life => {
//                   return (
//                     <tr key={life.id}>
//                     {/* <td dangerouslySetInnerHTML={{ __html: life.description.substring(0, 200)}}></td> */}
//                     <td>{life.username}</td>
//                     <td>{life.email}</td>
//                     <td>{life.role}</td>
//                       {(isSuper ==='true') && (
//                     <td className="button-group">
//                       <button className="btn section_btn" onClick={() => handleModalOpen('View',life)} style={{padding:'5px'}}>
//                         <FaEye/>
//                       </button>
//                         <button className="btn section_btn" onClick={() => handleModalOpen('Edit', life)} style={{padding:'5px'}}>
//                             <FaEdit/>
//                         </button>
//                         <button className="btn section_btn" onClick={() =>handleDeleteHero(life.id)} style={{padding:'5px'}}>
//                             <FaDeleteLeft/>
//                         </button>
//                     </td>
//                     )}
//                   </tr>
//                 );
//               })
//             ) : (
//               <tr><td colSpan="5">No data available</td></tr> 
//             )}
//           </tbody>
//         </table>
//       </div>

//       {showModal && (
//         <UsersModal 
//           type={modalType} 
//           data={modalData} 
//           onClose={handleCloseModal} 
//           onSave={() => {
//             handleCloseModal();
//             fetchUsers();
//           }} 
//         />
//       )}
//     </div>
//   );
// };

// export default UsersDash    ;
