import {React,useState,useEffect} from 'react';
import axios from 'axios';
import './History.css';
// import YouCat1 from '../../../assets/img/YouCat1.png'
// import YouCat2 from '../../../assets/img/YouCat2.png'
// import YouCat3 from '../../../assets/img/YouCat3.png'

const History = ({Ip}) => {
  const [histories,setHistory] = useState([]);
  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
        const response = await axios.get(`${Ip}Controllers/history.php`,{
    });
        // console.log("Fetched History:", response.data);   
        // console.log("Is array history:", Array.isArray(response.data));
        setHistory(response.data);
    } catch (error) {
        console.error('Error fetching History:', error.response ? error.response.data : error.message);
    }
  }

  return (
    <div className='history_container'>
 {histories.map((history, index) => {
              let images = [];
              try {
                images = JSON.parse(history.img);
                if (!Array.isArray(images)) {
                  throw new Error("Parsed JSON is not an array");
                }
              } catch (e) {
                console.error("Error parsing images JSON:", e);
                images = []; 
              }
              return(
        <div className="container" key={index}>
            <div className="d-flex flex-column align-items-center">
                <h1 className='text-black fw-bold text-uppercase'>history</h1>
                <hr size='10'/>
            </div>
           
            <div className="d-flex flex-row align-items-top justify-content-between gap-4 my-5 ">
              <div className="w-30">
                {/* <p style={{fontSize: '1rem'}} className='text-start text-black'>In the 1980s, with great effort by the pastors of the Church spread around the world, the Catechism of the Catholic Church was written, at the request of Pope Saint John Paul II. It was a “miracle,” according to Cardinal Joseph Ratzinger (the late Pope Benedict XVI at the time), who coordinated it. At World Youth Days in Rome, Toronto, Cologne and Sydney, she met young people who wanted to believe in Jesus and learn about the teachings of the Church. Pope Benedict Yukat p. 9</p> */}
                <p style={{fontSize: '1rem'}} className='text-start text-black'>{history.description1}</p>
              </div>
              <div className="w-30">
                {/* <p style={{fontSize: '1rem'}} className='text-start text-black'>From here, the idea of ​​Christian education for young people, YOUCAT, was born. Under the patronage of the Archbishop of Vienna, Christoph Schönborn, the YOUCAT book was born in 2010 in Munich, Germany. The structure of this book is the result of the experience of Christian education over generations. It consists of four sections: What we believe - How do we celebrate the Christian sacraments? - How do we have life in Christ? - How should we pray?</p> */}
                <p style={{fontSize: '1rem'}} className='text-start text-black'>{history.description2}</p>
              </div>
              <div className="w-30">
                <p style={{fontSize: '1rem'}} className='text-start text-black'>{history.description3}</p>
                {/* <p style={{fontSize: '1rem'}} className='text-start text-black'>In 2011, an agreement was concluded between the International Yukat Foundation - Germany - and the Youth Pastoral Office in the Patriarchal District of Bkerke-June, with the efforts of the Most Merciful Monsignor, Monsignor Tawfiq Bou Hadir, the founder of the office. Accordingly, the exclusive right was given to the Youth Pastoral Office, Bkerke-jeune, to translate, print, and publish the Yukat in Arabic, and all later Yukat books, in Lebanon and the Arab countries, and thus to all Arabic speakers in the world. Finally, His Holiness Pope Benedict XVI calls on young people:</p> */}
              </div>
            </div>
            <div className="image-container position-relative">
            {images.map((ima, idx) => {
              const img = ima.img;
              const imagPath = img.replace("../../../assets", "assets");
              const correctedImagePath = `${Ip}${imagPath}`;
              return(  
                <div className='position-absolute w-34 bg-white p-3 history_detail' key={idx}>
                  <img src={correctedImagePath} className='w-100' alt="" />
                  <p className='mt-3 text-start'>{ima.caption}</p>
                </div>
			      )})}
            </div>
            
        </div>
              )})}
    </div>
  )
}

export default History;
