import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const YoucatModal = ({ type, data, onClose, onSave, Ip }) => {
  const [desc1, setDesc1] = useState(data ? data.description1 : '');
  const [desc2, setDesc2] = useState(data ? data.description2 : '');
  const [desc3, setDesc3] = useState(data ? data.description3 : '');
  const [error, setError] = useState('');

//   let images = [];
//   try {
//     if (data && data.img) {
//       images = JSON.parse(data.img);
//     } else {
//       images = [{ img: '', caption: '' }, { img: '', caption: '' }, { img: '', caption: '' }];
//     }
//   } catch (e) {
//     console.error('Error parsing images JSON:', e);
//   }

//   const [images1, setImages1] = useState(images); 
//   const [newImages, setNewImages] = useState([null, null, null]); 
//   const fileInputRefs = useRef([]);

//   const handleTextChange = (e, index, field) => {
//     const updatedImages = [...images1];
//     updatedImages[index][field] = e.target.value;
//     setImages1(updatedImages);
//   };

//   const handleImageChange = (e, index) => {
//     const uploadedImage = e.target.files[0];
//     const updatedNewImages = [...newImages];
//     updatedNewImages[index] = uploadedImage;
//     setNewImages(updatedNewImages);
//   };

  const handleEdit = async () => {
    if (!desc1) {
      setError('Please enter all required fields!');
      return;
    }
    setError('');

    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('description1', desc1);
      formData.append('description2', desc2);
      formData.append('description3', desc3);
    //   formData.append('images', JSON.stringify(images1));

    //   newImages.forEach((image, index) => {
    //     if (image) {
    //       formData.append(`image${index + 1}`, image); 
    //     }
    //   });

      formData.append('Action', 'Edit');

      const response = await axios.post(`${Ip}Controllers/youcatarabic.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log('Youcat updated:', response.data);
      onSave();
    } catch (error) {
      console.error('Error updating Youcat:', error.response ? error.response.data : error.message);
      setError('Failed to save Youcat');
    }

    onClose();
  };

  return (
    <Modal show={type !== ''} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{type === 'Add' ? 'Add' : type === 'Edit' ? 'Edit' : 'View'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formdesc1">
            <Form.Label>Description 1</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description 1"
              value={desc1}
              onChange={(e) => setDesc1(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>
          <Form.Group controlId="formdesc2">
            <Form.Label>Description 2</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description 2"
              value={desc2}
              onChange={(e) => setDesc2(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>
          <Form.Group controlId="formdesc3">
            <Form.Label>Description 3</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description 3"
              value={desc3}
              onChange={(e) => setDesc3(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>

          {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            {images1.map((imgData, index) => {
                let imagPath = imgData.img;
                if (imagPath.startsWith("../../../assets")) {
                imagPath = imgData.img.replace("../../assets", "assets");
                } else if (imagPath.startsWith("../assets")) {
                imagPath = imgData.img.replace("../assets", "assets");
                }
                const correctedImagePath = `http://localhost/bkerkeyouth/src/admin/${imagPath}`;

                return (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
                    {imgData.img ? (
                    <img
                        src={correctedImagePath}
                        alt={`Image ${index + 1}`}
                        style={{ width: '70%', height: 'auto', cursor: 'pointer' }}
                        onClick={type === 'Edit' ? () => fileInputRefs.current[index].click() : undefined}
                    />
                    ) : (
                    <p></p>
                    )}
                </div>
                );
            })}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {images1.map((imgData, index) => (
                <div key={index} style={{ textAlign: 'center', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {type !== 'Add' && (
                    <>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, index)}
                        ref={(el) => (fileInputRefs.current[index] = el)}
                        style={{ display: 'none' }} 
                    />
                    <Form.Control
                        type="text"
                        placeholder="Enter the caption"
                        value={imgData.caption || ''}
                        onChange={(e) => handleTextChange(e, index, 'caption')}
                        readOnly={type === 'View'}
                        style={{ marginTop: '0px', width: '70%', textAlign: 'center' }}
                    />
                    </>
                )}
                </div>
            ))}
            </div> */}

          {error && <div className="text-danger mt-2">{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {type === 'Edit' && (
          <Button
            variant="primary"
            style={{ backgroundColor: '#0120ff', borderColor: '#0120ff' }}
            onClick={handleEdit}
          >
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default YoucatModal;
