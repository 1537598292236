import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from './screens/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import About from './screens/About';
import Library from './screens/Library';
import YouCat from './screens/YouCat';
import Contact from './screens/Contact';
import Gallery from './screens/Gallery';
import NotFound from './screens/NotFoundPage';
import ScrollToTop from './components/ScrollToTopPage';
import ScrollToTopBtn from './components/ScrollToTopButton/ScrollToTopButton';
import Index from './admin/index';
import Test from './Test';
import Login from './admin/Login';
 
function App() {
  // let Ip = 'http://localhost/BkerkeBack/'
  let Ip = 'http://bkerkejeune.org/'

  const location = useLocation();
  const shouldShowHeader = !location.pathname.startsWith('/admin');
  const shouldShowFooter = !location.pathname.startsWith('/admin');


  const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    
    console.log('Is Authenticated:', isAuthenticated);  
    if (isAuthenticated === null) {
      return <Navigate to="/" />;
    }
    return isAuthenticated === 'true' ? children : <Navigate to="/admin" />;
  }; 

  return (
    <div className="App">
      <ScrollToTop />
      {shouldShowHeader && location.pathname !== '*' && <Navbar Ip={Ip}/>}
      
      <Routes>
        <Route path='/' element={<Home Ip={Ip} />} />
        <Route path='/About' element={<About Ip={Ip}/>} />
        <Route path='/Library' element={<Library Ip={Ip} />} />
        <Route path='/YouCat' element={<YouCat Ip ={Ip} />} />
        <Route path='/Gallery' element={<Gallery Ip ={Ip} />} />
        <Route path='/Contact' element={<Contact Ip ={Ip} />} />
        <Route path='/Test' element={<Test />} />
        <Route path='/admin' element={<Login Ip ={Ip} />} />
        {/* <Route path='/pass' element={<Pass />} /> */}
        
        <Route
          path="/admin/index"
          element={
            <ProtectedRoute>
              <Index Ip = {Ip}/>
            </ProtectedRoute>
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>

      {shouldShowFooter && location.pathname !== '*' && <Footer />}
      <ScrollToTopBtn />
    </div>
  );
}

export default App;
