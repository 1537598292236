import React, { useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from "./assets/img/logo.png";
import './login.css';

const Login = ({setAuthenticated, Ip}) => {
  const [username, setUsername] = useState('');
  // const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  useEffect(() => {
    const isSuper = localStorage.getItem('isSuper');
    if (isSuper === 'true') {
      navigate('/admin/index');
    }
  }, [navigate]);


  const handleLogin = async () => {
    try {
      if (username.includes('@')) {
        if (!username.includes('.com')) {
          setError('Email must contain ".com"');
          return;
        }
      }
        const formData = new FormData();
        formData.append('username',username);
        // formData.append('email', email);
        formData.append('password', password);
        formData.append('Action','login');
        const response = await axios.post(`${Ip}Controllers/users.php`, formData);

      if (response.data.success === true) {
        // setAuthenticated(true); 
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/admin/index');
      } else {
        setError(response.data.message || 'Login failed');
      }
      //Super Role
      if (response.data.isSuper) {
        localStorage.setItem('isSuper', 'true');
        localStorage.setItem('isEditor', 'true');
      }
      else {localStorage.setItem('isSuper', 'false');}
      //Editor Role
      if (response.data.isEditor) {
        localStorage.setItem('isEditor', 'true');
      }
      else {localStorage.setItem('isEditor', 'false');}
    } catch (err) {
      console.error('An error occurred:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <main className="d-flex w-100 text-align-left">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <h1 className="h2">Welcome</h1>
                {/* Sign in to your account to continue */}
                <p className="lead"></p>
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="m-sm-4">
                    <div className="text-center">
                    <div className="img-circle-container">
                        <img
                          style={{ width: "90px", height: "90px",backgroundColor:'#0120ff'}}
                          src={logo}
                          alt="Bkerke Youth"
                          
                        />
                      </div>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                      }}
                    >
                      <div className="mb-3">
                        <label className="form-label" htmlFor="username">
                          <strong>Username or Email</strong>
                        </label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="username"
                          placeholder="Username or Email"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div> */}
                      <div className="mb-3">
                        <label className="form-label" htmlFor="password">
                          <strong>Password</strong>
                        </label>
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="password"
                          placeholder="Password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="text-center mt-5">
                        <button type="submit" className="btn btn-lg btn-primary">
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
