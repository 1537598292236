import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const OurStoryModal = ({ type, data, onClose, onSave, Ip }) => {
  const [desc1, setDesc1] = useState(data ? data.description1 : '');
  const [desc2, setDesc2] = useState(data ? data.description2 : '');
  const [desc3, setDesc3] = useState(data ? data.description3 : '');
  const [error, setError] = useState('');

  let images = [];
  try {
    if (data && data.img) {
      images = JSON.parse(data.img);
    } else {
      images = [{ img: '', caption: '' }, { img: '', caption: '' }, { img: '', caption: '' }];
    }
  } catch (e) {
    console.error('Error parsing images JSON:', e);
  }

  const [images1, setImages1] = useState(images); 
  const [newImages, setNewImages] = useState([null, null, null]); 

  const handleTextChange = (e, index, field) => {
    const updatedImages = [...images1];
    updatedImages[index][field] = e.target.value;
    setImages1(updatedImages);
  };

  // Handle image upload
  const handleImageChange = (e, index) => {
    const uploadedImage = e.target.files[0];
    const updatedNewImages = [...newImages];
    updatedNewImages[index] = uploadedImage;
    setNewImages(updatedNewImages);
  };

  const handleEdit = async () => {
    if (!desc1) {
      setError('Please enter all required fields!');
      return;
    }
    setError('');

    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('description1', desc1);
      formData.append('description2', desc2);
      formData.append('description3', desc3);
      formData.append('images', JSON.stringify(images1));

      // Append new images if uploaded
      newImages.forEach((image, index) => {
        if (image) {
          formData.append(`image${index + 1}`, image); 
        }
      });

      formData.append('Action', 'Edit');

      const response = await axios.post(`${Ip}Controllers/ourstory.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log('OurStory updated:', response.data);
      onSave();
    } catch (error) {
      console.error('Error updating OurStory:', error.response ? error.response.data : error.message);
      setError('Failed to save OurStory');
    }

    onClose();
  };
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ header: [1, 2, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
      ['clean'],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];

  return (
    <Modal show={type !== ''} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{type === 'Add' ? 'Add' : type === 'Edit' ? 'Edit' : 'View'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formdesc1">
            <Form.Label>Description 1</Form.Label>
            <ReactQuill
              value={desc1}
              onChange={(value) => setDesc1(value)}
              modules={modules}
              formats={formats}
              placeholder="Vision"
              theme="snow"
              readOnly={type === "View"}
          />
            {/* <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description 1"
              value={desc1}
              onChange={(e) => setDesc1(e.target.value)}
              readOnly={type === 'View'}
            /> */}
          </Form.Group>
          <Form.Group controlId="formdesc2">
            <Form.Label>Description 2</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description 2"
              value={desc2}
              onChange={(e) => setDesc2(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>
          <Form.Group controlId="formdesc3">
            <Form.Label>Description 3</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description 3"
              value={desc3}
              onChange={(e) => setDesc3(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>

          {images1.map((imgData, index) => {
            let imagPath = imgData.img;
            if (imagPath.startsWith("../../../assets")) {
              imagPath = imgData.img.replace("../../assets", "assets");
            }else if (imagPath.startsWith("../../assets")) {
              imagPath = imgData.img.replace("../../assets", "assets");
              } 
            else if (imagPath.startsWith("../assets")) {
              imagPath = imgData.img.replace("../assets", "assets");
            }
            const correctedImagePath = `${Ip}${imagPath}`;

            return (
              <div key={index}>
                <Form.Group controlId={`formImage-${index}`}>
                  <Form.Label>Image {index + 1}</Form.Label>
                  {imgData.img ? (
                    <img src={correctedImagePath} alt={`Img ${index + 1}`} style={{ width: '20%', height: 'auto' }} />
                  ) : (
                    <p>No image available</p>
                  )}
                  {type==='Edit' &&(
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, index)}
                    
                  />
                )}
                </Form.Group>

                <Form.Group controlId={`formCaption-${index}`}>
                  <Form.Label>Caption {index + 1}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the caption"
                    value={imgData.caption || ''}
                    onChange={(e) => handleTextChange(e, index, 'caption')}
                    readOnly={type === 'View'}
                  />
                </Form.Group>
              </div>
            );
          })}

          {error && <div className="text-danger mt-2">{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {type === 'Edit' && (
          <Button
            variant="primary"
            style={{ backgroundColor: '#0120ff', borderColor: '#0120ff' }}
            onClick={handleEdit}
          >
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default OurStoryModal;
