import { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useIdleTimeout = (timeout) => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      localStorage.removeItem('isAuthenticated'); 
      localStorage.removeItem('isSuper'); 
      localStorage.removeItem('isEditor'); 
      navigate('/admin');
    }, timeout);
  }, [navigate, timeout]); 

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];
    events.forEach(event => window.addEventListener(event, resetTimeout));

    resetTimeout(); 

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimeout));
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [resetTimeout]);

  return null;
};

export default useIdleTimeout;
