import React from 'react'
import Story from '../components/About/Story/Story'

const About = ({Ip}) => {
  return (
    <div>
      <Story Ip={Ip} />
    </div>
  )
}

export default About
