import React from 'react'
import footerImg from '../../assets/img/footerImg.png'
import './Footer.css'
import { Link } from 'react-router-dom'
import {FaWhatsapp, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa'
import { RiInstagramFill } from "react-icons/ri";
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    
  return (
    <div className='footer'>
        <div className='container footer_container h-100 d-flex flex-column flex-lg-row py-5'>
            <div className='footer_logo d-flex flex-row align-items-end justify-content-center'>
                <img src={footerImg} alt="" className='w-25'/>
                <div>
                    <h1 className='text_sm moon-font text-white text-start'>UNE EGLISE JEUNE,<br/>
                        <span className='text_secondary'>POUR LES JEUNES<br /> DE L'EGLISE</span>
                    </h1>
                </div>
            </div>
                <div className='footer_firstSection'>
                    <ul className='m-0 links d-flex flex-column'>
                        <li className={location.pathname === '/' ? 'active' : ''}>
                            <Link to='/'>Home</Link>
                        </li>
                        <li className={location.pathname === '/About' ? 'active' : ''}>
                            <Link to='/About'>About</Link>
                        </li>
                        <li className={location.pathname === '/JoinUs' ? 'active' : ''}>
                            <Link>Join Us</Link>
                        </li>
                        <li className={location.pathname === '/YouCat' ? 'active' : ''}>
                            <Link to='/YouCat'>YOUCAT Arabic</Link>
                        </li>
                        <li className={location.pathname === '/Contact' ? 'active' : ''}>
                            <Link to='/Contact'>Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className='footer_firstSection'>
                    <ul className='m-0 links d-flex flex-column'>
                        <li className={location.pathname === '/Explore' ? 'active' : ''}>
                            <Link>Explore</Link>
                        </li>
                        <li className={location.pathname === '/Gallery' ? 'active' : ''}>
                            <Link to='/Gallery'>Gallery</Link>
                        </li>
                        <li className={location.pathname === '/Podcasts' ? 'active' : ''}>
                            <Link>Podcasts</Link>
                        </li>
                        <li className={location.pathname === '/Books' ? 'active' : ''}>
                            <Link>Books</Link>
                        </li>
                        <li className={location.pathname === '/Newsletter' ? 'active' : ''}>
                            <Link>Newsletter</Link>
                        </li>
                    </ul>
                </div>
            <div className='footer_firstSection d-flex flex-column align-items-center justify-content-center gap-4'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <h5 className='text-white mb-3'>Let's Socialize</h5>
                    <div className='social-icons d-flex flex-row align-items-center gap-4'>
                        <FaFacebook size='25' fill='#fff' style={{cursor: 'pointer'}}/>
                        <RiInstagramFill size='25' fill='#fff' style={{cursor: 'pointer'}}/>
                        <FaTiktok size='25' fill='#fff' style={{cursor: 'pointer'}}/>
                    </div>
                </div>
                <Link className='btn fw-bold py-2 px-4 chat_btn mt-4'><FaWhatsapp size='25' fill='#fff'/> Chat with us</Link>
            </div>
        </div>
    </div>
  )
}

export default Footer
