import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown, DropdownButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const EventsModal = ({ type, data, onClose, onSave, Ip }) => {
  const [title, setTitle] = useState(data ? data.title : "");
  const [description, setDescription] = useState(data ? data.description : "");
  const [category, setCategory] = useState(data ? data.category : "");
  const [memorable, setMemorable] = useState(data ? parseInt(data.memorable, 10) : 0); // Ensure this is a number
  const [bgimg, setImage] = useState(data?.img ?? null);
  const [error, setError] = useState("");

  const handleSelect = (selectedCategory) => {
    setCategory(selectedCategory);
  };

  let images = [];
  // console.log(bgimg);
  try {
    if (data && data.multiple_img) {
      images = JSON.parse(data.multiple_img);
    }
  } catch (e) {
    console.error("Error parsing images JSON:", e);
  }

  const [images1, setImages1] = useState(images);
  const [newImages, setNewImages] = useState([null, null, null]);

  const handleMultipleImagesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setNewImages(selectedFiles);
  };

  // Fix the checkbox handling
  const handleCheckboxChange = (e) => {
    setMemorable(e.target.checked ? 1 : 0); // Set to 1 if checked, 0 if not
    console.log(e.target.checked ? 1 : 0); // Check the value in the console
  };

  const handleAdd = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("category", category);
      formData.append("memorable", memorable);
      formData.append("images", JSON.stringify(images1));
      formData.append("bgimg", bgimg);
      console.log("bgimage: " + bgimg);
      console.log("hello", formData);

      newImages.forEach((image, index) => {
        if (image) {
          formData.append(`image${index + 1}`, image);
        }
      });

      formData.append("Action", "Add");

      const response = await axios.post(
        `${Ip}Controllers/events.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Event added:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error adding event:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save event");
    }

    onClose();
  };

  const handleEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("category", category);
      formData.append("memorable", memorable);
      formData.append("bgimg", bgimg);
      formData.append("images", JSON.stringify(images1));

      newImages.forEach((image, index) => {
        if (image) {
          formData.append(`image${index + 1}`, image);
        }
      });

      formData.append("Action", "Edit");

      const response = await axios.post(
        `${Ip}Controllers/events.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Event updated:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error updating event:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save event");
    }

    onClose();
  };

  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "Add"
            ? "Add Event"
            : type === "Edit"
            ? "Edit Event"
            : "View Event"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>
          {(type === "Add" || type === "Edit") && (
            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={category || "Select Category"}
                onSelect={handleSelect}
                variant="primary"
                style={{ marginRight: "10px" }}
              >
                <Dropdown.Item eventKey="gallery">Gallery</Dropdown.Item>
                <Dropdown.Item eventKey="youcat">Youcat</Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          )}
          <Form.Group controlId="formMemorable">
            <Form.Check
              type="checkbox"
              label="Memorable"
              checked={memorable === 1} // Check if memorable is 1
              onChange={handleCheckboxChange}
            />
          </Form.Group>
          {(type === "Add" || type === "Edit") && (
            <Form.Group controlId="Image">
              <Form.Label>Upload Background Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Form.Group>
          )}
          {images1.map((img, index) => {
            let imagPath = img;
            if (imagPath.startsWith("../../../assets")) {
              imagPath = img.replace("../../../assets", "assets");
            } 
            else if (imagPath.startsWith("../../assets")) {
              imagPath = img.replace("../../assets", "assets");
            }else if (imagPath.startsWith("../assets")) {
              imagPath = img.replace("../assets", "assets");
            }
            const correctedImagePath = `${Ip}${imagPath}`;

            return (
              <div key={index}>
                <Form.Group controlId={`formImage-${index}`}>
                  <Form.Label>Image {index + 1}</Form.Label>
                  {img ? (
                    <img
                      src={correctedImagePath}
                      alt={`Img ${index + 1}`}
                      style={{ width: "10%", height: "auto" }}
                    />
                  ) : (
                    <p></p>
                  )}
                </Form.Group>
              </div>
            );
          })}

          {(type === "Add" || type === "Edit") && (
            <Form.Group controlId="formNewImages">
              <Form.Label>Upload Images</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                multiple
                onChange={handleMultipleImagesChange}
              />
            </Form.Group>
          )}
          {error && <div className="text-danger mt-2">{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {(type === "Add" || type === "Edit") && (
          <Button
            variant="primary"
            style={{ backgroundColor: "#0120ff", borderColor: "#0120ff" }}
            onClick={type === "Add" ? handleAdd : handleEdit}
          >
            {type === "Add" ? "Add" : "Save Changes"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EventsModal;
