import React, { useState, useRef, useEffect } from 'react';
import { IoIosSearch, IoIosClose } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import LogoNos from '../../assets/img/logo_nos.png';
import JoinModal from '../Modal/JoinModal';

const Navbar = ({Ip}) => {
    const location = useLocation();
    const [searchOpen, setSearchOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenu, setIsMobileMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
  

    const searchRef = useRef(null);

    useEffect(() => {
        if (isMobileMenu) {
            document.body.classList.add('responClass') // Disable scroll
        } else {
            document.body.classList.remove('responClass') // Disable scroll
            // Enable scroll
        }
    }, [isMobileMenu]);

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
    };

    const toggleSearch = () => {
        setSearchOpen(true);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setSearchOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div>
            <div className={`Navbar d-flex align-items-center shadow ${isScrolled ? 'fixed' : ''}`}>
                <div className={`menu-container flex-grow-1 px-4 ${isScrolled ? 'scrolled' : ''}`}>
                    <Link to='/'>
                        {isScrolled ? <img src={LogoNos} alt="" className='logo-group scrolled' /> : <img src={Logo} alt="" className='logo-group' /> }
                    </Link>
                    <ul className='menu d-flex flex-row align-items-center gap-4 m-0'>
                        <li className={location.pathname === '/About' ? 'active' : ''}>
                            <Link to='/About'>
                                About
                            </Link>
                        </li>
                        <li className={location.pathname === '/Library' ? 'active' : ''}>
                            <Link to='/Library'>
                                Library
                            </Link>
                        </li>
                        <li className={location.pathname === '/Gallery' ? 'active' : ''}>
                            <Link to='/Gallery'>
                                Gallery
                            </Link>
                        </li>
                        <li>
                            <Link to='#' onClick={handleModalShow}>
                                Join
                            </Link>
                        </li>
                        <JoinModal Ip={Ip} showModal = {showModal} handleModalClose = {handleModalClose} title="Join Our Team" submitText="Join Now" actionKey = 'Join'/>
                        <li className={location.pathname === '/YouCat' ? 'active' : ''}>
                            <Link to='/YouCat'>
                                Youcat Arabic
                            </Link>
                        </li>
                        <li className={location.pathname === '/Contact' ? 'active' : ''}>
                            <Link to='/Contact'>
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className='menu-container-mobile flex-grow-1 px-4 gap-3'>
                    <Link className='logo_link order-1' to='/'>
                        <img src={Logo} alt="" className='logo-group' />
                    </Link>
                    <Link onClick={()=> setIsMobileMenu(!isMobileMenu)} className='d-flex align-items-center order-0'>
                        {isMobileMenu ? <IoIosClose fill='#fff' size={45} /> : <RxHamburgerMenu fill='#fff' color='#fff' size={35} />}
                    </Link>
                    {isMobileMenu && 
                        <ul className='mobile_menu-links'>
                            <li>About</li>
                            <li>Library</li>
                            <li>Gallery</li>
                            <li>join</li>
                            <li>Youcat</li>
                        </ul>
                    }
                    
                </div>

                <div className="search-container" ref={searchRef}>
                    <div className={`search ${searchOpen ? 'open' : ''}`} onClick={toggleSearch}>
                        <IoIosSearch size='20' className="search-icon " />
                        {searchOpen && (
                            <input 
                                type="text" 
                                className="search-input form-control text-white" 
                                placeholder="Search..." 
                            />
                        )}
                    </div>
                </div>
                {/* <Link to='/admin'>
                    <FaUser size='20' fill="#fff"/>
                </Link> */}
            </div>
        </div>
    );
}

export default Navbar;
