import React from 'react'
import Hero from '../components/Gallery/Hero/Hero'
import EventYear from '../components/Gallery/EventYear/EventYear'
import MemoEvent from '../components/Gallery/MemoEvent/MemoEvent'

const Gallery = ({Ip}) => {
  return (
    <>
        <Hero />
        <MemoEvent Ip={Ip}/>
        <EventYear Ip={Ip} />
    </>
  )
}

export default Gallery