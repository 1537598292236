import React, { useState } from 'react';
import './sidebar.css';
import { IoIosHome } from "react-icons/io";
import { MdPortrait } from "react-icons/md";
import { FaBookOpen } from "react-icons/fa6";
import { FaRegAddressBook } from "react-icons/fa";
import { FaHighlighter } from "react-icons/fa";
import { PiTelevisionSimpleFill } from "react-icons/pi";
import { BiSolidDonateBlood } from "react-icons/bi";
import { IoLibrarySharp } from "react-icons/io5";
import { PiApplePodcastsLogoBold } from "react-icons/pi";
import { FaHistory } from "react-icons/fa";
import { MdRoundaboutLeft } from "react-icons/md";
import { MdEmojiEvents } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import logo from "./assets/img/logo.png";
import { BiSolidCategory } from "react-icons/bi";
import { MdWorkHistory } from "react-icons/md";
import { GiFlagObjective } from "react-icons/gi";
import { GiArabicDoor } from "react-icons/gi";
import { MdContactMail } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { FaUserSecret } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { HiMiniPuzzlePiece } from "react-icons/hi2";
import { MdOutlineAppRegistration } from "react-icons/md";
import { FaMessage } from "react-icons/fa6";
import { FaBook } from "react-icons/fa6";
import './assets/css/dashboard.css'


const Sidebar = ({ setActiveComponent }) => {
  const [openIndexMenu, setOpenIndexMenu] = useState(false);
  const [openHomeMenu, setOpenHomeMenu] = useState(false);
  const [openLibraryMenu, setOpenLibraryMenu] = useState(false);
  const [openYouCatMenu, setOpenYouCatMenu] = useState(false);
  const [openAboutMenu, setOpenAboutMenu] = useState(false);
  const [openContactusMenu, setOpenContactusMenu] = useState(false);
  const [openMembersMenu, setOpenMembersMenu] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const handleMenuToggle = (menu,component = null) => {
    if (menu === 'index') {
      setOpenIndexMenu(!openIndexMenu);
    } else if (menu === 'home') {
      setOpenHomeMenu(!openHomeMenu);
      setOpenLibraryMenu(false); 
      setOpenYouCatMenu(false);
      setOpenAboutMenu(false);
      setOpenContactusMenu(false);
      setOpenMembersMenu(false);
    } else if (menu === 'library') {
      setOpenLibraryMenu(!openLibraryMenu);
      setOpenHomeMenu(false);
      setOpenYouCatMenu(false);
      setOpenAboutMenu(false);
      setOpenContactusMenu(false);
      setOpenMembersMenu(false);
      // setOpenPageMenu(false); // Close Pages menu when opening His Writings
    } else if (menu === 'youcat') {
      setOpenYouCatMenu(!openYouCatMenu);
      setOpenLibraryMenu(false);
      setOpenHomeMenu(false);
      setOpenAboutMenu(false);
      setOpenContactusMenu(false);
      setOpenMembersMenu(false);
    } else if (menu === 'about') {
      setOpenAboutMenu(!openAboutMenu);
      setOpenLibraryMenu(false);
      setOpenHomeMenu(false);
      setOpenYouCatMenu(false);
      setOpenContactusMenu(false);
      setOpenMembersMenu(false);
    } else if (menu === 'contactus') {
      setOpenContactusMenu(!openContactusMenu);
      setOpenLibraryMenu(false);
      setOpenHomeMenu(false);
      setOpenAboutMenu(false);
      setOpenLibraryMenu(false);
      setOpenYouCatMenu(false);
      setOpenMembersMenu(false);
    }
    else if (menu === 'members') {
      setOpenMembersMenu(!openMembersMenu);
      setOpenLibraryMenu(false);
      setOpenHomeMenu(false);
      setOpenAboutMenu(false);
      setOpenLibraryMenu(false);
      setOpenYouCatMenu(false);
      setOpenContactusMenu(false);
    }
    if(component){
      setActiveComponent(component);
    }
  };
  const goHome=()=>{
    window.location.href="../../";
  }
  const handleArrowClick = () => {
    setIsRotated(!isRotated); 
  };

  const isSuper = localStorage.getItem('isSuper');
  const isEditor = localStorage.getItem('isEditor');
  // console.log('Is Super:', isSuper);
  // console.log('Is Editor:', isEditor);
  return (
    <nav id="sidebar" className="sidebar js-sidebar" style={{position:'Fixed'}}>
      <div className="sidebar-content js-simplebar">
      <button 
        className="sidebar-brand d-flex flex-column align-items-center" 
        onClick={goHome}
        style={{ backgroundColor: '#0120ff',border:'none', color: 'white' }}
        >
        <img
            src={logo}
            alt="Patriarch Douaihy"
            className="img-fluid rounded-circle mb-2"
            style={{ width: '70px', height: '70px' }}
        />
        <span className="text-center" style={{fontSize:'20px'}}>Bkerke Youth</span>
    </button>
        <ul className="sidebar-nav">
         {/* Home Menu */}
        <li className="sidebar-header pageBtn" onClick={() => handleMenuToggle('home')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
            <IoIosHome />
            &nbsp;HOME
            <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown  className={`arrow_rotate ${openHomeMenu ? 'rotate' : ''}`} alt="" />
            </div>

          </div>
          {openHomeMenu && (
            <ul className="sidebar-header_menu p-0 mt-3">
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Hero')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <MdPortrait /> Hero
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('SubHero')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    <FaBookOpen/> SubHero
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('BookHighlight')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <FaRegAddressBook/> Book Highlight
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Highlight')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <FaHighlighter/> Highlight
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Vision')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    <PiTelevisionSimpleFill/> Vision Mission 
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('User')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    <BiSolidDonateBlood/>Donate
                  </span>
                </button>
              </li>
            </ul>
          )}
        </li>

        {/*About Menu */}
        <li className="sidebar-header pageBtn" onClick={() => handleMenuToggle('about')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
           <MdRoundaboutLeft/>&nbsp;About
           <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown className={`arrow_rotate ${openAboutMenu ? 'rotate' : ''}`} alt="" />
            </div>
          </div>
          {openAboutMenu && (
            <ul className="sidebar-header_menu p-0 mt-3">
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('OurStory')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <MdWorkHistory/> Our Story
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Objective')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    <GiFlagObjective/> Objective
                  </span>
                </button>
              </li>
            </ul>
          )}
        </li>
        {/*Library Menu */}
        <li className="sidebar-header pageBtn" onClick={() => handleMenuToggle('library')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
           <IoLibrarySharp/>&nbsp;LIBRARY
           <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown className={`arrow_rotate ${openLibraryMenu ? 'rotate' : ''}`} alt="" />
            </div>
          </div>
          {openLibraryMenu && (
            <ul className="sidebar-header_menu p-0 mt-3">
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Podcasts')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <PiApplePodcastsLogoBold/> Podcasts Categories
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Gallery')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <MdEmojiEvents/> Events
                  </span>
                </button>
              </li>
            </ul>
          )}
        </li>
        {/* YouCat */}
        <li className="sidebar-header pageBtn" onClick={() => handleMenuToggle('youcat')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
            <BiSolidCategory/>&nbsp;YouCat
            <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown className={`arrow_rotate ${openYouCatMenu ? 'rotate' : ''}`} alt="" />
            </div>
          </div>
          {openYouCatMenu && (
            <ul className="sidebar-header_menu p-0 mt-3">
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('history')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <FaHistory/> History
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('youcatarabic')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    < GiArabicDoor/> YouCat Arabic 
                  </span>
                </button>
              </li>
              {/* <li className="sidebar-item events-activities" onClick={() => setActiveComponent('events')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    <MdEmojiEvents/> Events
                  </span>
                </button>
              </li> */}
            </ul>
          )}
        </li>
        {/* Contact Us*/}
        {(isSuper==='true') && (
        <li className="sidebar-header pageBtn" onClick={() => handleMenuToggle('contactus')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
            <MdContactMail/>&nbsp;ContactUs 
            <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown className={`arrow_rotate ${openContactusMenu ? 'rotate' : ''}`} alt="" />
            </div>
          </div>
          {openContactusMenu && (
            <ul className="sidebar-header_menu p-0 mt-3">
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('edit')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <FaEdit/> Layout
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('messages')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <FaMessage/> Messages
                  </span>
                </button>
              </li>
            </ul>
          )}
        </li>
        )}
        {/* Members */}
        <li className="sidebar-header pageBtn" onClick={() => handleMenuToggle('members')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
            <FaPeopleGroup/>&nbsp;Community
            <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown className={`arrow_rotate ${openMembersMenu ? 'rotate' : ''}`} alt="" />
            </div>
          </div>
          {openMembersMenu && (
            <ul className="sidebar-header_menu p-0 mt-3">
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Members')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <HiMiniPuzzlePiece/> Joined Members
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('Registred')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                    <MdOutlineAppRegistration/> Members/Events 
                  </span>
                </button>
              </li>
              <li className="sidebar-item events-activities" onClick={() => setActiveComponent('purchasers')}>
                <button className="sidebar-link d-flex gap-2">
                  <span className="align-middle">
                  <FaBook/> Book Purchasers
                  </span>
                </button>
              </li>
            </ul>
          )}
        </li>
        {/* Users */}
        {(isSuper==='true') && (
        <li className="sidebar-header pageBtn" onClick={() => setActiveComponent('users')}>
          <div className="sidebar-header_title d-flex flex-row align-items-center">
            <FaUserSecret/>&nbsp;Users 
            <div className='d-flex justify-content-end w-100'>
              <IoIosArrowDown className={`arrow_rotate ${isRotated ? 'rotate' : ''}`} alt="" />
            </div>
          </div>
        </li>
        )}
        </ul>
      </div>
      </nav>
  );
};

export default Sidebar;
