import React, { useState } from "react";
import { Modal, Button, Form, Dropdown, DropdownButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import MyEditor from './MyEditor';
import axios from "axios";
// import './button.css';
import "./modal.css";

const UsersModal = ({ type, data, onClose, onSave, Ip }) => {
  const [username, setUsername] = useState(data ? data.username : "");
  const [email, setEmail] = useState(data ? data.email : "");
  const [role, setRole] = useState(data ? data.role : "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSelect = (selectedCategory) => {
    setRole(selectedCategory);
  };

  const handleAdd = async () => {
    if (!username.trim() || !email.trim() || !role.trim() || !password.trim()) {
      setError("Please enter all required fields!");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address!");
      return;
    }
    if (password !== confirmPassword) {
      setError("The passwords don't match!");
      return;
    } else {
      setError("");
    }
    setError("");

    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("email", email);
      formData.append("role", role);
      formData.append("password", password);
      formData.append("Action", "Add");

      const response = await axios.post(
        `${Ip}Controllers/users.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data.errors && response.data.errors.length > 0) {
        const errorMessages = response.data.errors.join('\n');
        setError(errorMessages);
        return;
    }
      console.log("User saved:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error saving User:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save User");
    }

    onClose();
  };

  const handleEdit = async () => {
    if (!username || !email || !role) {
      setError("Please enter all required fields!");
      return;
    }
    setError("");
    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("role", role);
      formData.append("Action", "Edit");

      const response = await axios.post(
        `${Ip}Controllers/users.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("User updated:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error updating User:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save news");
    }

    onClose();
  };
  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "Add" ? "Add" : type === "Edit" ? "Edit" : "View"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              readOnly={type === "View" || type === "Edit"}
            />
          </Form.Group>
          <Form.Group controlId="formemail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="emailAddress"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              readOnly={type === "View" || type === "Edit"}
            />
          </Form.Group>
          <Form.Group controlId="formRole" className="custom-select-group">
            {/* <Form.Label>Role</Form.Label>
            <Form.Control
                as="select"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                readOnly={type === 'View'}
                className="custom-select" 
            >
                <option value="">Select a role...</option> 
                <option value="super">Super</option> 
                <option value="editor">Editor</option>
                <option value="viewer">Viewer</option>
            </Form.Control> */}
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={role || "Select Role"}
                onSelect={handleSelect}
                variant="primary"
                style={{ marginRight: "10px" }}
                readOnly={type === "View"}
              >
                <Dropdown.Item eventKey="super">Super</Dropdown.Item>
                <Dropdown.Item eventKey="editor">Editor</Dropdown.Item>
                {/* <Dropdown.Item eventKey="guest">Guest</Dropdown.Item>
                <Dropdown.Item eventKey="hr">HR</Dropdown.Item> */}
              </DropdownButton>
            </Form.Group>
          </Form.Group>
          {type === "Add" && (
            <div>
              <Form.Group controlId="pass">
                <Form.Label>Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </Form.Group>

              <Form.Group controlId="pass1">
                <Form.Label>Re-enter the Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Re-enter your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                    {/* Toggle icon */}
                  </button>
                </div>
              </Form.Group>
            </div>
          )}

        {error && <div className="text-danger mt-2" style={{ whiteSpace: 'pre-line' }}>{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {(type === "Add" || type === "Edit") && (
          <Button
            variant="primary"
            style={{ backgroundColor: "#0120ff", borderColor: "#0120ff" }}
            onClick={type === "Add" ? handleAdd : handleEdit}
          >
            {type === "Add" ? "Add" : "Save Changes"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UsersModal;
