import React, { useState,useEffect } from 'react';
import { Modal, Button, Form ,Dropdown,DropdownButton} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import MyEditor from './MyEditor';
import axios from 'axios';
// import './button.css';

const PodcastsModal = ({ type, data, onClose, onSave, Ip }) => {
  const [title, setTitle] = useState(data ? data.title : '');
  const [description, setDescription] = useState(data ? data.description : '');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [category, setCategory] = useState(data ? data.category : ''); 
  
  const handleSelect = (selectedCategory) => {
    setCategory(selectedCategory);
  };

  const [links1, setLinks] = useState(() => {
    if (data && data.link) {
      try {
        return JSON.parse(data.link);
      } catch (e) {
        console.error("Error parsing button JSON:", e);
        return [{ link: '' }];
      }
    }
    return [{ link: '' }];
  });
  
  const handleTextChange = (e, index) => {
    const newLinks = [...links1]; 
    newLinks[index].link = e.target.value; 
    setLinks(newLinks);
  };
  

//   const sendUpdatedButtons = (updatedButtons) => {
//     const formData = new FormData();
//     formData.append('buttons', JSON.stringify(updatedButtons));
//   }

  const handleAdd = async () => {
    if (!title || !description ||!category) {
      setError('Please enter all required fields!');
      return;
    }
    setError('');
  
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('img', image);
      formData.append('category', category);
      formData.append('links', JSON.stringify(links1));
      formData.append('Action', 'Add');

    
      const response = await axios.post(`${Ip}Controllers/podcasts.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('hero saved:', response.data);
      onSave(); 
    } catch (error) {
      console.error('Error saving hero:', error.response ? error.response.data : error.message);
      setError('Failed to save hero');
    }
  
    onClose();
  };
  
  const handleEdit = async () => {
    if (!title || !description) {
      setError('Please enter all required fields!');
      return;
    }
    setError('');
  
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('img', image);
      formData.append('category', category);
      formData.append('links', JSON.stringify(links1));
      formData.append('Action', 'Edit');
  
      const response = await axios.post(`${Ip}Controllers/podcasts.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('Hero updated:', response.data);
      onSave();
    } catch (error) {
      console.error('Error updating Hero:', error.response ? error.response.data : error.message);
      setError('Failed to save news');
    }
  
    onClose();
  };
    // const handleView = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('id', data.id);
  //     formData.append('Action','View')
      
  //     const response = await axios.post('http://localhost/patriarchdouaihy/src/admin/Controllers/steps.php', formData, {
  //       headers: { 'Content-Type': 'multipart/form-data' }
  //     });
  //     console.log('Success:', response.data);
  //     onSave(); 
  //   } catch (error) {
  //     console.error('Error :', error.response ? error.response.data : error.message);
  //     setError('Failed to view');
  //   }
    
  //   onClose();
  // };

  return (
    <Modal show={type !== ''} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
      <Modal.Title>
        {type === 'Add' ? 'Add' : type === 'Edit' ? 'Edit' : 'View'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>

          <Form.Group controlId="formdesc">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter the description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>
          {(type === "Add" || type === "Edit") && (
            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={category || "Select Category"}
                onSelect={handleSelect}
                variant="primary"
                style={{ marginRight: "10px" }}
              >
                <Dropdown.Item eventKey="podcasts">PODCASTS</Dropdown.Item>
                <Dropdown.Item eventKey="ainoto">AINOTO</Dropdown.Item>
                <Dropdown.Item eventKey="heritage">HERITAGE</Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          )}
          
          {links1.map((btn, index) => (
            <div key={index}>
                <Form.Group controlId={`formTitle-${index}`}>
                <Form.Label>Link {index + 1}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter the button title"
                    value={btn.link || ''}
                    onChange={(e) => handleTextChange(e, index)} 
                    readOnly={type === 'View'}
                />
                </Form.Group>
            </div>
            ))}

          {(type === 'Add' || type === 'Edit')&&(
          <Form.Group controlId="formImage" className="mt-3">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Form.Group>
          )}
          {error && <div className="text-danger mt-2">{error}</div>}
          {(type === 'View' || type ==='Edit') && (
          <Form.Group controlId="formDescription" className="mt-3">
            <Form.Label>Image</Form.Label>
            {(() => {
              let correctedImagePath;
                  if (data.img.includes('../../../assets')) {
                    correctedImagePath = data.img.replace('../../../assets', 'assets');
                } else if (data.img.includes('../../assets')) {
                  correctedImagePath =data.img.replace('../../assets', 'assets');
                 }
                 else if (data.img.includes('../assets')) {
                    correctedImagePath =data.img.replace('../assets', 'assets');
                } else {
                    correctedImagePath = data.img; 
                }
              const image = `${Ip}${correctedImagePath}`;
              return (
                <div>
                  <img src={image} alt={data.title} style={{ maxWidth: '15%', height: 'auto' }} />
                </div>
              );
            })()}
          </Form.Group>
    )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
            Close
        </Button>
        {(type === 'Add' || type === 'Edit') && (
        <Button 
          variant="primary"
          style={{ backgroundColor: '#0120ff', borderColor: '#0120ff' }} 
          onClick={type === 'Add' ? handleAdd : handleEdit}
        >
          {type === 'Add' ? 'Add' : 'Save Changes'}
        </Button>
      )}
      </Modal.Footer>
    </Modal>
  );
};

export default PodcastsModal;
