import React, { useState } from 'react';
import Hero from '../components/Library/Hero/Hero';
import Podcasts from '../components/Library/Podcasts/Podcasts';
import Ainoto from '../components/Library/Ainoto/Ainoto';
import Heritage from '../components/Library/Heritage/Heritage';
import NewReleases from '../components/Library/NewReleases/NewReleases';

const Library = ({Ip}) => {
  const [isSelected, setIsSelected] = useState('podcasts');

  const handleClick = (section) => {
    setIsSelected(section);
  };

  return (
    <>
      <Hero isSelected={isSelected} setIsSelected={setIsSelected} handleClick={handleClick} />
      {isSelected === 'podcasts' && <Podcasts Ip={Ip} isSelected={isSelected} />}
      {isSelected === 'ainoto' && <Ainoto Ip={Ip} isSelected={isSelected} />}
      {isSelected === 'heritage' && <Heritage Ip={Ip} isSelected={isSelected} />}
      <NewReleases />
    </>
  );
};

export default Library;