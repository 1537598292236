import React, { useState } from 'react'
import Hero from '../components/Home/Hero/Hero'
import SubHero from '../components/Home/SubHero/SubHero'
import BookHighlight from '../components/Home/BookHighlight/BookHighlight'
import Highlight from '../components/Home/Highlight/Highlight'
import VisionMission from '../components/Home/VisionMission/VisionMission'
import Donate from '../components/Home/Donate/Donate'

const Home = ({Ip}) => {
  const [showModal, setShowModal] = useState(false);
  const [showBookModal, setShowBookModal] = useState(false);
    
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  const handleBookModalShow = () => setShowBookModal(true);
  const handleBookModalClose = () => setShowBookModal(false);

  return (
    <div>
      <Hero Ip={Ip} showModal = {showModal} handleModalClose={handleModalClose} handleModalShow={handleModalShow}/>
      <SubHero Ip={Ip} />
      <BookHighlight Ip ={Ip} showModal = {showBookModal} handleModalClose={handleBookModalClose} handleModalShow={handleBookModalShow}/>
      <Highlight Ip ={Ip} />
      <VisionMission Ip ={Ip} />
      <Donate Ip ={Ip} />
    </div>
  )
}

export default Home
