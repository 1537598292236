import React from 'react'
import Hero from '../components/Contact/Hero/Hero'
import Form from '../components/Contact/Form/Form'

const Contact = ({Ip}) => {
  return (
    <div style={{backgroundColor: '#F0F0F0'}}>
      <Hero />
      <Form Ip={Ip} />
    </div>
  )
}

export default Contact
