import {React,useEffect,useState} from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay } from "react-icons/fa";
import './MemoEvents.css'

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div 
            className='rounded-circle d-flex flex-row align-items-center justify-content-center position-absolute end-0' 
            style={{width: '60px', height: '60px', transform: 'translate(29%, -34rem)', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255,0.5)'}}
        >
            <div 
                className='rounded-circle bg_primary d-flex flex-row align-items-center justify-content-center' 
                style={{width: '35px', height: '35px'}}
                onClick={onClick}
            >
                <FaPlay size='15' fill='#fff'/>
            </div>
        </div>
    );
}

const settings = {
    easing: 'linear',
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
};

const MemoEvent = ({Ip}) => {
    const [events,setEvents]=useState([]);
    useEffect(() => {
        fetchEvents();
      }, []);
    
      const fetchEvents = async () => {
        try {
            const response = await axios.get(`${Ip}Controllers/events.php`,{
                params: {
                    Action: 'youcat',
                    memorable: true 
                }
        });
            // console.log("Fetched Events:", response.data);   
            // console.log("Is array memo:", Array.isArray(response.data));
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching Events:', error.response ? error.response.data : error.message);
        }
    };
    return (
        <div className='memoEvent_container' >
            <div className='container'>
                <h2 className='text-start fw-bold mb-4 text-uppercase'>Events</h2>
                <h5 className='text-start text-uppercase'>MEMORABLE EVENTS</h5>
            </div>
            <div className="container memo_container">
                <Slider {...settings}>
                    {events.map((memo, index) => {
                        const img = memo.img;
                        let imagPath = img;
                        if (imagPath.startsWith("../../../assets")) {
                          imagPath = img.replace("../../../assets", "assets");
                        }else if (imagPath.startsWith("../../assets")) {
                            imagPath = img.replace("../../assets", "assets");
                        } else if (imagPath.startsWith("../assets")) {
                        imagPath = img.replace("../assets", "assets");
                        }
                        const correctedImagePath = `${Ip}${imagPath}`;
                        const multipleImages = JSON.parse(memo.multiple_img || '[]');
                        const imgCount = multipleImages.length;
                        return(
                        <div className="container my-6" key={index}>
                            <div className='memoEventsBg position-relative' style={{backgroundImage: `url(${correctedImagePath})`}}>
                                <div className='bottom-content'>
                                    <div className="text-start text-white container py-2">
                                        {imgCount >=2 ?(
                                        <p className='fw-bold m-0'>{imgCount} Items</p>
                                        ):
                                        <p className='fw-bold m-0'>{imgCount} Item</p>
                                        }
                                        <h5 className='fw-bold'>{memo.title}</h5>
                                        <p  style={{fontSize: '0.8rem'}}>{memo.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        );
                   })}
                </Slider>
            </div>
        </div>
    );
};

export default MemoEvent;
