import React from 'react'
import Hero from '../components/YouCat/Hero/Hero'
import History from '../components/YouCat/History/History'
import About from '../components/YouCat/About/About'
import MemoEvent from '../components/YouCat/MemoEvent/MemoEvent'
import EventYear from '../components/YouCat/EventYear/EventYear'

const YouCat = ({Ip}) => {
  return (
    <>
      <Hero />
      <History Ip ={Ip} />
      <About Ip ={Ip} />
      <MemoEvent Ip ={Ip} />
      <EventYear Ip ={Ip} />
    </>
  )
}

export default YouCat
