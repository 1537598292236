import React,{useState,useEffect} from 'react';
import axios from 'axios';
import BlueBg from '../../../assets/img/subBg.png';
import GreenBg from '../../../assets/img/bg-green.png';
import Micro from '../../../assets/img/micro.png';
import Book from '../../../assets/img/book.png';
import Cedar from '../../../assets/img/cedar.png';
import './SubHero.css';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'

const SubHero = ({Ip}) => {
  const [subHeroes, setSub] = useState([]);
  // const subItems = [
  //   {
  //     imgSrc: Micro,
  //     title: 'PODCASTS',
  //     description: 'Listen with your heart',
  //     linkTo: '/podcasts',
  //   },
  //   {
  //     imgSrc: Book,
  //     title: 'AÏNOTO',
  //     description: 'Quench your spiritual thirst',
  //     linkTo: '/ainoto',
  //   },
  //   {
  //     imgSrc: Cedar,
  //     title: 'HERITAGE',
  //     description: 'Connect with your roots',
  //     linkTo: '/heritage',
  //   },
  // ];
  useEffect(() => {
    fetchSub();
  }, []);

  const fetchSub = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/subhero.php`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setSub(response.data);
      // console.log("Fetched SubHeroes:", response.data); 
    } catch (error) {
      console.error('Error fetching SubHeroes:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className='d-flex flex-column flex-lg-row align-items-center justify-content-evenly subhero_hero'>
      {subHeroes.map((sub, index) => {
        const img = sub.icon;
        const imagPath = img.replace("../../../assets", "assets");
        const correctedImagePath = `${Ip}${imagPath}`;
        return(
        <Link to={sub.linkto} key={index}>
          <Fade cascade>
            <div className='sub-item'>
              <div className='sub-item_main' style={{ backgroundImage: `url(${BlueBg})` }}>
                <div className='sub_sub-item_main' style={{ backgroundImage: `url(${GreenBg})` }}></div>
                <img src={correctedImagePath} alt={sub.title} className='w-75' />
              </div>
              <div className="text-content">
                <h5 className='text-white m-0'>{sub.title}</h5>
                <p className='text_secondary'>{sub.description}</p>
              </div>
            </div>
          </Fade>
        </Link>
        );
      })}
    </div>
  );
};

export default SubHero;
