import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const ContactUsModal = ({ type, data, onClose, onSave, Ip }) => {
  const [desc1, setDesc1] = useState(data ? data.description1 : "");
  const [img1, setImg1] = useState("");
  const [img1Name, setImg1Name] = useState("");
  const [img1Changed, setImg1Changed] = useState(false); // Track if img1 has changed
  const [title1, setTitle1] = useState("");
  const [desc2, setDesc2] = useState("");
  const [img2, setImg2] = useState("");
  const [img2Name, setImg2Name] = useState("");
  const [img2Changed, setImg2Changed] = useState(false);
  const [title2, setTitle2] = useState("");
  const [desc3, setDesc3] = useState("");
  const [img3, setImg3] = useState("");
  const [img3Name, setImg3Name] = useState("");
  const [img3Changed, setImg3Changed] = useState(false);
  const [title3, setTitle3] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (data && typeof data === "object") {
      try {
        const parsedTitle = JSON.parse(data.title);
        if (Array.isArray(parsedTitle) && parsedTitle.length > 0) {
          setTitle(parsedTitle[0]);
        }
      } catch (e) {
        console.error("Error parsing title JSON:", e);
      }
      const parseDescription = (description, setDesc, setImg, setTitleFunc) => {
        try {
          const parsedDesc = JSON.parse(description);
          if (Array.isArray(parsedDesc) && parsedDesc.length > 0) {
            setDesc(parsedDesc[0].description);
            setImg(parsedDesc[0].img);
            setTitleFunc(parsedDesc[0].title);
          }
        } catch (e) {
          console.error("Error parsing description JSON:", e);
        }
      };

      parseDescription(data.description1, setDesc1, setImg1, setTitle1);
      parseDescription(data.description2, setDesc2, setImg2, setTitle2);
      parseDescription(data.description3, setDesc3, setImg3, setTitle3);
    }
  }, [data]);

  const fileInputRefs = useRef([]);

  const handleImageChange = (e, index) => {
    const uploadedImage = e.target.files[0];
    if (uploadedImage) {
      const filename = uploadedImage.name;
      // Update the name and file only if a new image is uploaded
      if (index === 0) {
        setImg1(uploadedImage);
        setImg1Name(filename);
        setImg1Changed(true);
      } else if (index === 1) {
        setImg2(uploadedImage);
        setImg2Name(filename);
        setImg2Changed(true);
      } else if (index === 2) {
        setImg3(uploadedImage);
        setImg3Name(filename);
        setImg3Changed(true);
      }
    }
  };

  const handleEdit = async () => {
    if (!desc1 || !desc2 || !desc3) {
      setError("Please enter all required fields!");
      return;
    }
    setError("");

    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append(
        "title",
        JSON.stringify([{ title: title.title, subtitle: title.subtitle }])
      );

      const imagePath = "../assets/uploads/";
      formData.append(
        "description1",
        JSON.stringify([
          {
            img: img1Changed ? imagePath + img1Name : img1,
            title: title1,
            description: desc1,
          },
        ])
      );
      formData.append(
        "description2",
        JSON.stringify([
          {
            img: img2Changed ? imagePath + img2Name : img2,
            title: title2,
            description: desc2,
          },
        ])
      );
      formData.append(
        "description3",
        JSON.stringify([
          {
            img: img3Changed ? imagePath + img3Name : img3,
            title: title3,
            description: desc3,
          },
        ])
      );

      // Append images if they have changed
      if (img1Changed) formData.append("img1", img1);
      if (img2Changed) formData.append("img2", img2);
      if (img3Changed) formData.append("img3", img3);
      formData.append("Action", "Edit");

      const response = await axios.post(
        `${Ip}Controllers/contactus.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("ContactUs updated:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error updating ContactUs:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save ContactUs");
    }

    onClose();
  };

  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{type === "Edit" ? "Edit" : "View"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the Title"
              value={title.title}
              onChange={(e) => setTitle({ ...title, title: e.target.value })}
              readOnly={type === "View"}
            />
          </Form.Group>
          <Form.Group controlId="formSubtitle">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the Subtitle"
              value={title.subtitle}
              onChange={(e) => setTitle({ ...title, subtitle: e.target.value })}
              readOnly={type === "View"}
            />
          </Form.Group>

          {/* Description 1 */}
          <Form.Label
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textDecoration: "underline",
            }}
          >
            Description 1:
          </Form.Label>
          <Form.Group
            style={{ marginLeft: "5%", marginBottom: "2%" }}
            controlId="formdesc1"
          >
            {type === "View" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {(() => {
                  let img = img1 || "";
                  if (typeof img === "string") {
                    if (img.includes("../../assets")) {
                      img = img.replace("../../assets", "../assets");
                    }
                    if (img.includes("../assets")) {
                      img = img.replace("../assets", "assets");
                    }
                  }
                  const correctedImagePath = `${Ip}${img}`;
                  return (
                    <img
                      src={correctedImagePath}
                      alt="Description 1"
                      style={{
                        maxHeight: "120px",
                        marginRight: "15px",
                        cursor: "default",
                        flexShrink: 0,
                      }}
                    />
                  );
                })()}
                <div
                  style={{
                    flex: 1,
                    marginLeft: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Title 1
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={title1}
                    readOnly
                    style={{ marginBottom: "10px", width: "100%" }}
                  />
                  <Form.Label>Text 1</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={desc1}
                    readOnly
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}

            {type === "Edit" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 0)}
                  ref={(el) => (fileInputRefs.current[0] = el)}
                  style={{ marginBottom: "10px" }}
                />
                <Form.Label style={{ fontWeight: "bold" }}>Title 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Title for Description 1"
                  value={title1}
                  onChange={(e) => setTitle1(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
                <Form.Label>Text 1</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter the Description 1"
                  value={desc1}
                  onChange={(e) => setDesc1(e.target.value)}
                />
              </div>
            )}
          </Form.Group>

          {/* Description 2 */}
          <Form.Label
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textDecoration: "underline",
            }}
          >
            Description 2:
          </Form.Label>
          <Form.Group
            style={{ marginLeft: "5%", marginBottom: "2%" }}
            controlId="formdesc2"
          >
            {type === "View" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {(() => {
                  let img = img2 || "";
                  if (typeof img === "string") {
                    if (img.includes("../../assets")) {
                      img = img.replace("../../assets", "../assets");
                    }
                    if (img.includes("../assets")) {
                      img = img.replace("../assets", "assets");
                    }
                  }
                  const correctedImagePath = `${Ip}${img}`;
                  return (
                    <img
                      src={correctedImagePath}
                      alt="Description 1"
                      style={{
                        maxHeight: "120px",
                        marginRight: "15px",
                        cursor: "default",
                        flexShrink: 0,
                      }}
                    />
                  );
                })()}

                {/* Title and Description Container */}
                <div
                  style={{
                    flex: 1,
                    marginLeft: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Title 2
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={title2}
                    readOnly
                    style={{ marginBottom: "10px", width: "100%" }}
                  />
                  <Form.Label>Text 2</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={desc2}
                    readOnly
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}

            {type === "Edit" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 0)}
                  ref={(el) => (fileInputRefs.current[0] = el)}
                  style={{ marginBottom: "10px" }}
                />
                <Form.Label style={{ fontWeight: "bold" }}>Title 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Title for Description 1"
                  value={title2}
                  onChange={(e) => setTitle2(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
                <Form.Label>Text 2</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter the Description 2"
                  value={desc2}
                  onChange={(e) => setDesc2(e.target.value)}
                />
              </div>
            )}
          </Form.Group>

          {/* Description 3 */}
          <Form.Label
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textDecoration: "underline",
            }}
          >
            Description 3 :
          </Form.Label>
          <Form.Group
            style={{ marginLeft: "5%", marginBottom: "2%" }}
            controlId="formdesc3"
          >
            {type === "View" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {(() => {
                  let img = img3 || "";
                  if (typeof img === "string") {
                    if (img.includes("../../assets")) {
                      img = img.replace("../../assets", "../assets");
                    }
                    if (img.includes("../assets")) {
                      img = img.replace("../assets", "assets");
                    }
                  }
                  const correctedImagePath = `${Ip}${img}`;
                  return (
                    <img
                      src={correctedImagePath}
                      alt="Description 3"
                      style={{
                        maxHeight: "120px",
                        marginRight: "15px",
                        cursor: "default",
                        flexShrink: 0,
                      }}
                    />
                  );
                })()}

                <div
                  style={{
                    flex: 1,
                    marginLeft: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Title 3
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={title3}
                    readOnly
                    style={{ marginBottom: "10px", width: "100%" }}
                  />
                  <Form.Label>Text 3</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={desc3}
                    readOnly
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}

            {type === "Edit" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 0)}
                  ref={(el) => (fileInputRefs.current[0] = el)}
                  style={{ marginBottom: "10px" }}
                />
                <Form.Label style={{ fontWeight: "bold" }}>Title 3</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Title for Description 1"
                  value={title3}
                  onChange={(e) => setTitle3(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
                <Form.Label>Text 3</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter the Description 3"
                  value={desc1}
                  onChange={(e) => setDesc3(e.target.value)}
                />
              </div>
            )}
          </Form.Group>

          {error && <div style={{ color: "red" }}>{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {type === "Edit" ? (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleEdit}>
              Save Changes
            </Button>
          </>
        ) : (
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ContactUsModal;
