import React, { useState, useEffect } from "react";
import axios from "axios";
import ContactUsModal from "../Modals/ContactUsModal";
import "./pages.css";
import { FaEye, FaEdit } from "react-icons/fa";
import DataTable from "react-data-table-component";

const ContactUsDash = ({ title, Ip }) => {
  const [Contact, setContact] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchContactUs();
  }, []);

  const fetchContactUs = async () => {
    try {
      const response = await axios.get(
        `${Ip}Controllers/contactus.php`,
        {
          params:{Action:'fetch'},
          headers: { "Content-Type": "application/json" },
        }
      );
      setContact(response.data);
    } catch (error) {
      console.error(
        "Error fetching Contact:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleModalOpen = (type, data = null) => {
    setModalType(type);
    setModalData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType("");
    setModalData(null);
  };

  // const filteredContact = Contact.filter((contact) => {
  //   return (
  //     contact.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     contact.subtitle.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });

  const columns = [
    {
      name: "Title",
      selector: (row) => {
        let titles = [];
        try {
          titles = JSON.parse(row.title);
          if (!Array.isArray(titles)) throw new Error("Parsed JSON is not an array");
        } catch (e) {
          console.error("Error parsing titles JSON:", e);
        }
        return titles.length > 0 ? titles.map((tit) => tit.title).join(", ") : "N/A";
      },
      sortable: true,
    },
    {
      name: "SubTitle",
      selector: (row) => {
        let subtitles = [];
        try {
          subtitles = JSON.parse(row.title);
          if (!Array.isArray(subtitles)) throw new Error("Parsed JSON is not an array");
        } catch (e) {
          console.error("Error parsing subtitles JSON:", e);
        }
        return subtitles.length > 0 ? subtitles.map((tit) => tit.subtitle).join(", ") : "N/A";
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row align-items-center button-group">
          <button
            className="btn section_btn"
            title="View"
            onClick={() => handleModalOpen("View", row)}
            style={{ padding: "5px" }}
          >
            <FaEye />
          </button>
          <button
            className="btn section_btn"
            title="Edit"
            onClick={() => handleModalOpen("Edit", row)}
            style={{ padding: "5px" }}
          >
            <FaEdit />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: "left", fontSize: "25px", fontWeight: "bold", marginLeft: "10px" }}>
          {title}
        </h1>
      </div>
      {/* <div className="search-bar" style={{ padding: "20px 0" }}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div> */}
      <div className="lht_table table table-hover" style={{ width: "85vw" }}>
        <DataTable
          columns={columns}
          data={Contact}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: "flex-end",
              },
            },
          }}
        />
      </div>
      {showModal && (
        <ContactUsModal
          Ip = {Ip}
          type={modalType}
          data={modalData}
          onClose={handleCloseModal}
          onSave={() => {
            handleCloseModal();
            fetchContactUs();
          }}
        />
      )}
    </div>
  );
};

export default ContactUsDash;
