import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Modals.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

function BookModal({
  showModal,
  handleModalClose,
  title,
  submitText,
  actionKey,
  bookImg,
  bookTitle,
  bookId,
  Ip
}) {
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    qty: '',
    bookTitle: bookTitle,
    bookId: bookId,
    fullName: '',
    email: '',
    phoneNumber: '',
    address: '',
    introduction: '',
    acceptTerms: false
  });
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleModalClose();
  };

  const handleAdd = async () => {
    const { fullName, email, phoneNumber, address, introduction, acceptTerms, qty } = formData;
  
    if (!fullName || !email || !phoneNumber || !address || !introduction || !acceptTerms || !qty) {
      setError('Please fill all required fields!');
      return;
    }
    
    setError(''); 
  
    try {
      const formData = new FormData();
      formData.append('fullname', fullName);
      formData.append('email', email);
      formData.append('phonenumber', phoneNumber);
      formData.append('address', address);
      formData.append('introduction', introduction);
      formData.append('qty', qty);
      formData.append('bookid', bookId);
      formData.append('booktitle', bookTitle);
      formData.append('acceptterms', acceptTerms ? '1' : '0');
      formData.append('Action', 'book');
  
      const response = await axios.post(`${Ip}Controllers/join.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      sendMail(formData, response);
      
      if (response.data.error) {
        setError(response.data.error);
      } else {
        console.log('Purchase:', response.data);
        handleModalClose(); 
      }
  
    } catch (error) {
      console.error('Error joining the user:', error.response ? error.response.data : error.message);
      setError(error.response?.data.error || 'Failed to save purchase'); 
    }
  };
  
  // let phpMailerLocation = 'http://localhost/bkerkeyouth/src/'
  let phpMailerLocation = 'http://bkerkejeune.org/'
  const sendMail = async (formData, response) => {
    try {
      const response = await axios.post(
          `${phpMailerLocation}Phpmailer.php`,
          formData, 
          {
              headers: { "Content-Type": "multipart/form-data" },
          }
      );
      console.log("Mail sent successfully:", response.data);
  } catch (error) {
      console.error(
          "Error sending mail:",
          error.response ? error.response.data : error.message
      );
  }
};


  return (
    <>
      <Modal show={showModal} className='modalClass' onHide={handleModalClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title className='text-uppercase book_title text_primary'>
            <h3>{title}</h3>
          </Modal.Title>
          <form onSubmit={handleSubmit}>
            <div className='d-flex flex-column flex-lg-row gap-5'>
              <div className='w-100 py-2 px-5' style={{ height: '15rem', background: '#DEDEDE' }}>
                <img src={bookImg} className='w-auto h-100' alt="" />
              </div>
            </div>
            {error && <div className='alert alert-danger'>{error}</div>}
            <div className='d-flex flex-column flex-lg-row gap-5 my-5'>
              <input
                type="number"
                name="qty"
                value={formData.qty}
                min={1}
                onChange={handleChange}
                placeholder='Quantity'
                className="form-control"
              />
              <input
                type="text"
                name="bookTitle"
                value={formData.bookTitle}
                disabled
                placeholder='Book'
                className="form-control"
              />
            </div>
            <div className='d-flex flex-column flex-lg-row gap-5 my-5'>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder='Full Name...'
                className="form-control"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder='Email...'
                className="form-control"
              />
            </div>
            <div className='d-flex flex-column flex-lg-row gap-5 my-5'>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder='Phone Number...'
                className="form-control"
              />
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder='Address...'
                className="form-control"
              />
            </div>
            <div>
              <input
                type="text"
                name="introduction"
                value={formData.introduction}
                onChange={handleChange}
                placeholder='Notes...'
                className="form-control"
              />
            </div>
            <div className='mt-5'>
              <Link type="submit" onClick={handleAdd} className='btn_modal text-uppercase'>{submitText}</Link>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none!important' }}>
          <div className='d-flex flex-row gap-2 w-100'>
            <input
              type="checkbox"
              id='termsRules'
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleChange}
            />
            <label htmlFor="termsRules">
              I accept <span className='fw-bold'>Terms of Service and Privacy Policy</span>
            </label>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BookModal;
