import Lib1 from '../../../assets/img/dailyPrayer.png'
import Lib2 from '../../../assets/img/thePrayer.png'

const ReleasesArray = [
    {
        img: Lib1,
        qty: '1',
        title: 'Your Daily Prayer',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
        img: Lib2,
        qty: '1',
        title: 'Your Daily Prayer',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
        img: Lib1,
        qty: '1',
        title: 'Your Daily Prayer',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
        img: Lib2,
        qty: '1',
        title: 'Your Daily Prayer',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
        img: Lib1,
        qty: '1',
        title: 'Your Daily Prayer',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
]

export default ReleasesArray;