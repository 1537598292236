import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Heritage.css';
import { FaPlay } from "react-icons/fa";
import ComingSoon from '../ComingSoon/ComingSoon';

const Heritage = ({isSelected, Ip}) => {
    const navigate = useNavigate();
    const [HeritageArray, setHeritage] = useState([]);
    
    useEffect(() => {
        fetchHeritage();
    }, [isSelected]);
    
    const fetchHeritage = async () => {
        try {
            const response = await axios.get(`${Ip}Controllers/podcasts.php?category=${isSelected}`, {
                headers: { 'Content-Type': 'application/json' }
            });
            setHeritage(response.data);
        } catch (error) {
            console.error('Error fetching Heritage:', error.response ? error.response.data : error.message);
        }
    };
    
    const handlePlayClick = (target) => {
        navigate(target);
    };

    const HeritageCount = HeritageArray.length;
    const podcastCardWidth = HeritageCount > 0 ? '20%' : '100%';
    const comingSoonWidth = `${100 - (20 * HeritageCount)}%`;

    return (
        <div className='podcasts_container container'>
            <h2 className='text-start fw-bold mb-4 text-uppercase'>Heritage</h2>
            <h5 className='text-start text-uppercase'>New Releases</h5>
            <div className="d-flex flex-row align-items-center justify-content-between gap-3 mt-3">
                {HeritageArray.map((release, index) => {
                    const img = release.img;
                    let imagPath=img;
                        if (imagPath.includes('../../../assets')) {
                            imagPath = imagPath.replace('../../../assets', 'assets');
                        }else if (imagPath.includes('../../assets')) {
                            imagPath =imagPath.replace('../../assets', 'assets');
                        } else if (imagPath.includes('../assets')) {
                            imagPath =imagPath.replace('../assets', 'assets');
                        } else {
                            imagPath = imagPath; 
                        }
                    const correctedImagePath = `${Ip}${imagPath}`;
                    return (
                        <div
                            className='podcast_card d-flex flex-column align-items-center podcasts-bg_grey'
                            key={index}
                            style={{ width: podcastCardWidth }}
                        >
                            <div className=''>
                                <img src={correctedImagePath} alt={release.title} className='podcast_card_img' />
                            </div>
                            <div className='podcast_card_hover_content d-flex flex-column align-items-start position-absolute w-100 px-3'>
                                <h6 className='podcast_card_title text-start fw-bold'>{release.title}</h6>
                            </div>
                            <div className='podcast_card_content d-flex flex-row align-items-end justify-content-between position-absolute px-3' style={{ zIndex: '3' }}>
                                <p className='podcast_card_author m-0 mt-2 text-start w-75 podcast_card_desc' style={{ fontSize: '0.8rem' }}>{release.description}</p>
                                <div className='p-3 d-flex flex-row align-items-center justify-content-center bg_secondary rounded-circle play_btn' onClick={() => handlePlayClick(release.link)} style={{ cursor: 'pointer' }}>
                                    <FaPlay color='#001ad4' />
                                </div>
                            </div>
                        </div>
                    );
                })}
                <ComingSoon widthBg = {comingSoonWidth}/>
            </div>
        </div>
    );
}

export default Heritage;
