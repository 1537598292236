import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MembersModal from '../Modals/MembersModal'; 
import '../sidebar.css'; 
import './pages.css';
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';

const MembersDash = ({ title, Ip,isSuper,isEditor}) => {
  const [heroes, setHeroes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchHero();
  }, []);

  const fetchHero = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/join.php`, {
        params:{category: 'join'},
        headers: { 'Content-Type': 'application/json' }
      });
      setHeroes(response.data);
    } catch (error) {
      console.error('Error fetching Hero:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, heroes = null) => {
    setModalType(type);
    setModalData(heroes);
    setShowModal(true);
  };

  const handleDeleteHero = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });
  
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Action', 'delete');
  
        await axios.post(`${Ip}Controllers/join.php`, formData);
        fetchHero();

        Swal.fire(
          'Deleted!',
          'Your item has been deleted.',
          'success'
        );
      } else {
        Swal.fire(
          'Cancelled',
          'Your item is safe :)',
          'error'
        );
      }
    } catch (error) {
      console.error('Error deleting Hero:', error.response ? error.response.data : error.message);
      Swal.fire(
        'Error!',
        'There was an error deleting the hero item.',
        'error'
      );
    }
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(heroes); 
    const workbook = XLSX.utils.book_new(); 
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members"); 
    XLSX.writeFile(workbook, "Joined_Members.xlsx"); 
  };

  const columns = [
    {
      name: 'Full Name',
      selector: row => row.fullname,
      sortable: true,
      // style: { maxWidth: '5vw' },
    },
    {
      name: 'Phone Number',
      selector: row => row.phonenumber,
      sortable: true,
      // style: { maxWidth: '15vw' },
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      // style: { maxWidth: '25vw' },
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
      // style: { maxWidth: '10vw' },
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="d-flex flex-row align-items-center button-group">
          <button className="btn section_btn" title="View" onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          {(isSuper==='true') && (<button className="btn section_btn" title="Delete" onClick={() => handleDeleteHero(row.id)} style={{ padding: '5px' }}>
            <FaDeleteLeft />
          </button>
          )}
        </div>
      ),
      // style: { maxWidth: '10vw' },
    },
  ];
  
  
  // Filter heroes based on search term
  const filteredHeroes = heroes.filter(hero => {
    return hero.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
           hero.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
           hero.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
           hero.introduction.toLowerCase().includes(searchTerm.toLowerCase()) ||
           hero.events.toLowerCase().includes(searchTerm.toLowerCase())
  });

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className='d-flex flex-row align-items-center justify-content-between gap-4' style={{width: '75vw'}}>
        <div className="px-5" style={{ width: '100%', paddingTop: '3em', paddingBottom: '0' }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          />
        </div>
        <div style={{ width: '20vw', paddingTop: '3em', paddingBottom: '0' }}>
          <button
            onClick={handleExportToExcel}
            style={{
              padding: '10px 20px',
              backgroundColor: '#0120ff',
              color: 'white',
              width: '14vw',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Export to Excel
          </button>
        </div>
      </div>

      <div className="lht_table table table-hover" style={{maxWidth: '85vw',paddingTop:'30px'}}>
        <DataTable
          columns={columns}
          data={filteredHeroes}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: 'flex-end',
              },
            },
          }}
        />
      </div>

      {showModal && (
        <MembersModal 
          Ip = {Ip}
          type={modalType} 
          data={modalData} 
          onClose={handleCloseModal} 
          onSave={() => {
            handleCloseModal();
            fetchHero();
          }} 
        />
      )}
    </div>
  );
};

export default MembersDash;
