import React, { useEffect ,useState} from 'react'
import axios from 'axios';
import './Form.css'
import { Link } from 'react-router-dom'
// import { IoPhonePortraitOutline, IoLocationOutline  } from "react-icons/io5";
// import { MdOutlineMail } from "react-icons/md";
// import Phone from '../../../assets/img/phone.png'
// import Msg from '../../../assets/img/msg.png'
// import Pin from '../../../assets/img/pin.png'
import Swal from 'sweetalert2'

const Form = ({Ip}) => {
    const [contactus, setContact] = useState([]);
    const [fullname,setFullname] = useState([]);
    const [email, setEmail] = useState([]);
    const [phone, setPhone] = useState([]);
    const [subject, setSubject] = useState([]);
    const [msg,setMsg]=useState([]);
    useEffect(()=>{
        fetchContact();
    },[]);
    const fetchContact = async () => {
        try {
          const response = await axios.get(`${Ip}Controllers/contactus.php`, {
            params:{Action: 'fetch'},
            headers: { 'Content-Type': 'application/json' }
          });
          setContact(response.data);
        //   console.log("Fetched Contact:", response.data);
        } catch (error) {
          console.error('Error fetching ContactUs:', error.response ? error.response.data : error.message);
        }
    };  

  // let phpMailerLocation = 'http://localhost/bkerkeyouth/src/'
  let phpMailerLocation = 'http://bkerkejeune.org/'

const SendForm = async () => {
  const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to send this message?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!'
  });
  if (result.isConfirmed) {
    const loadingToast = Swal.fire({
        title: 'Sending...',
        text: 'Please wait while your message is being sent.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
      try {
          const formData = new FormData();
          formData.append('fullName', fullname);
          formData.append('email', email);
          formData.append('phoneNumber', phone);
          formData.append('subject', subject);
          formData.append('msg', msg);
          formData.append('Action', 'form');

          await axios.post(`${phpMailerLocation}Phpmailer.php`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        //   await axios.post(`${Ip}Controllers/contactus.php`, formData, {
        //     headers: { "Content-Type": "multipart/form-data" },
        // });
      //   await axios.post(`${Ip}Controllers/sendmail.php`, formData, {
      //     headers: {
      //         "Content-Type": "multipart/form-data", // Required for FormData
      //         "Accept": "application/json",         // To specify the expected response type
      //     },
      // });
          Swal.fire({
              title: 'Success!',
              text: 'Your message has been sent successfully.',
              icon: 'success',
              confirmButtonText: 'Okay'
          });
      } catch (error) {
          console.error('Error fetching ContactUs:', error.response ? error.response.data : error.message);
          Swal.fire({
              title: 'Error!',
              text: 'There was an issue sending your message. Please try again later.',
              icon: 'error',
              confirmButtonText: 'Okay'
          });
      }
  }
};
  return (
    <div className='form_height'>
        <div className="container">
            <h1 className='text-start mb-5'>Contact Us</h1>
            {contactus.map((content,index)=>{
                let description1 = [];
                try {
                    description1 = JSON.parse(content.description1);
                  if (!Array.isArray(description1)) {
                    throw new Error("Parsed JSON is not an array");
                  }
                } catch (e) {
                  console.error("Error parsing descritpion1 JSON:", e);
                  description1 = []; 
                }

                let title = [];
                try {
                    title = JSON.parse(content.title);
                  if (!Array.isArray(title)) {
                    throw new Error("Parsed JSON is not an array");
                  }
                } catch (e) {
                  console.error("Error parsing title JSON:", e);
                  title = []; 
                }

                let description2 = [];
                try {
                    description2 = JSON.parse(content.description2);
                  if (!Array.isArray(description2)) {
                    throw new Error("Parsed JSON is not an array");
                  }
                } catch (e) {
                  console.error("Error parsing descritpion2 JSON:", e);
                  description2 = []; 
                }
                
                let description3 = [];
                try {
                    description3 = JSON.parse(content.description3);
                  if (!Array.isArray(description3)) {
                    throw new Error("Parsed JSON is not an array");
                  }
                } catch (e) {
                  console.error("Error parsing descritpion2 JSON:", e);
                  description3 = []; 
                }
                return(
                <div className="form-content d-flex flex-row align-items-center justify-content-center gap-3" key={index}>
                <div className="w-60 form_container">
                    {title.map((tit, idx) => (
                    <div className="form_container-header text-start" key={idx}>
                        <h4 className='m-0 fw-bold'>{tit.title}</h4>
                        <p className='m-0'>{tit.subtitle}</p>
                    </div>
            ))}
                    <div className="form_container-body text-start">
                        <div className="row my-3">
                            <div className="col-md-6">
                                <input type="text" id ='fullname' className="form-control w-47" placeholder='Full name'
                                 value={fullname}
                                 onChange={(e) => setFullname(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <input type="email" id ='email' className="form-control w-47" placeholder='Email' 
                                 value={email}
                                 onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-6">
                                <input type="tel" id='phone' className="form-control w-47" placeholder='Phone'
                                 value={phone}
                                 onChange={(e) => setPhone(e.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <input type="Subject" id='subject' className="form-control w-47" placeholder='Subject'
                                value={subject}
                                onChange={(e)=>setSubject(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-12 mt-3">
                            <textarea
                                rows='10'
                                className='form-control custom-textarea lined-textarea'
                                placeholder="Message"
                                value={msg}
                                onChange={(e)=>setMsg(e.target.value)}
                                />
                            </div>
                        </div>
                        <Link className='text-white bg-black btn' onClick={SendForm}>Send</Link>
                    </div>
                </div>
              
                <div className="w-40 form-sideBar">
                {description1.map((desc1, hello) => {
                    const img = desc1.img;
                    let imagPath;
                    if (img.includes('../../../assets')) {
                      imagPath = img.replace('../../../assets', 'assets');
                    } else if (imagPath.includes('../../assets')) {
                      imagPath = imagPath.replace('../../assets', 'assets');
                    } else if (img.includes('../assets')) {
                      imagPath = img.replace('../assets', 'assets');
                    } else {
                      imagPath = img; 
                    }
                    const correctedImagePath = `${Ip}${imagPath}`;
                return(
                    <div className="sidebar_item d-flex flex-row justify-content-center gap-3 p-3" key={hello}>
                        <div className="item_icon pt-3 pr-3">
                            <img src={correctedImagePath} alt="" style={{width: '50px'}}/>
                        </div>
                            <div className="item_text-content text-start">
                                <h5 className='fw-bold text_primary'>{desc1.title}</h5>
                                <p>{desc1.description}</p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p> */}
                        </div>
                    </div>
                        );
                    })}
                {description2.map((desc2, hii) => {
                    const img = desc2.img;
                    const imagPath = img.replace("../../../assets", "assets");
                    const correctedImagePath = `${Ip}${imagPath}`;
                return(
                    <div className="sidebar_item d-flex flex-row justify-content-center gap-3 p-3" key={hii}>
                        <div className="item_icon pt-3 pr-3">
                            <img src={correctedImagePath} alt="" style={{width: '50px'}}/>
                        </div>
                            <div className="item_text-content text-start">
                                <h5 className='fw-bold text_primary'>{desc2.title}</h5>
                                <p>{desc2.description}</p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p> */}
                        </div>
                    </div>
                        );
                    })}
                {description3.map((desc3, pt) => {
                    const img = desc3.img;
                    const imagPath = img.replace("../../../assets", "assets");
                    const correctedImagePath = `${Ip}${imagPath}`;
                return(
                    <div className="sidebar_item d-flex flex-row justify-content-center gap-3 p-3" key={pt}>
                        <div className="item_icon pt-3 pr-3">
                            <img src={correctedImagePath} alt="" style={{width: '50px'}}/>
                        </div>
                            <div className="item_text-content text-start">
                                <h5 className='fw-bold text_primary'>{desc3.title}</h5>
                                <p>{desc3.description}</p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p> */}
                        </div>
                    </div>
                        );
                    })}
                    
                </div>
            
            </div>
            );
    })}
        </div>
    </div>
  )
}

export default Form
