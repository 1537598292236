import React from 'react'
import { Link } from 'react-router-dom'
import BgGreen from '../../../assets/img/bg-green.png'
import './Donate.css'
import { AttentionSeeker } from 'react-awesome-reveal';
import Fade from 'react-awesome-reveal';


const Donate = () => {
  return (
    <div className='d-flex flex-lg-row flex-column align-items-center donate_container container mb-5' style={{marginTop: '6rem'}}>
        <div className="row">
            <div className='col-lg-4 col-md-12'>
                <AttentionSeeker effect="pulse">
                    <h1 className='text_primary fw-bold moon-font'>LET'S SPREAD JESUS LOVE <span className='text_secondary'>TOGETHER</span></h1>
                </AttentionSeeker>
            </div>
            <div className="col-lg-8 col-md-12">
                <div className="row d-flex flex-column flex-lg-row align-items-center">
                        <div className='col-md-6 donate_bg d-flex flex-column align-items-center gap-3'>
                            <AttentionSeeker effect="pulse" delay={150}>
                                <h1 className='text_secondary fw-bold moon-font'>DONATE</h1>
                                <Link className='btn text_primary fw-bold moon-font py-2 donate_btn' style={{width: '14rem'}}>NOW</Link>
                            </AttentionSeeker>
                        </div>
                    <div className='col-md-6 donate_bg position-relative d-flex flex-column align-items-center justify-content-center'>
                        <img src={BgGreen} alt='' className='w-40 '/>
                        <div className='position-absolute join_txt d-flex align-items-center justify-content-center'>
                            <AttentionSeeker effect="pulse" delay={300}>
                                <div className='d-flex flex-column align-items-center'>
                                    <h1 className='text_primary fw-bold moon-font'>JOIN BKJ</h1>
                                    <Link className='btn text_primary fw-bold moon-font py-2 join_btn mt-4' style={{width: '14rem'}}>NOW</Link>
                                </div>
                            </AttentionSeeker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Donate
