import React from 'react'
import ComingSoonImg from '../../../assets/img/comingSoon.png'

const ComingSoon = ({widthBg}) => {
  return (
    <div style={{width: widthBg}}>
        <div className='d-flex justify-content-center align-items-center' style={{background: '#d9d9d9', height: '24rem', backgroundImage: `url(${ComingSoonImg})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <h1 className='text-dark fw-bold w-25 d-flex flex-column align-items-center text-uppercase' style={{fontSize: '3.5rem'}}>Coming Soon</h1>
        </div>
    </div>
  )
}

export default ComingSoon