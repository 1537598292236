import React, { useState } from 'react';
import { FaUserLock } from "react-icons/fa";
import { RiLogoutBoxLine } from "react-icons/ri";

const Logout = () => {
  const [hover, setHover] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated'); 
    localStorage.removeItem('isSuper'); 
    localStorage.removeItem('isEditor'); 
    window.location.href = '/admin';
  };

  return (
    <div 
      onClick={handleLogout} 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: 'flex',    
        alignItems: 'center',
        cursor: 'pointer',  
        margin: '20px', 
        marginBottom: '40px',
        zIndex: 100,
        transition: 'transform 0.3s ease',
        transform: hover ? 'scale(1.1)' : 'scale(1)',
      }}
    >
      <FaUserLock 
        style={{
            color: hover ? '#0120f3' : '#0120ff',
            fontSize: '24px',
            transition: 'transform 0.3s ease, color 0.3s ease',
            marginRight: '8px',
            transform: hover ? 'scale(1.2)' : 'scale(1)', 
        }} 
      />
      <span 
        style={{
          fontWeight: 'bold',
          color: hover ? '#0120f3' : 'black', 
        }}
      >
        Logout
      </span> 
    </div>
  );
};

export default Logout;
