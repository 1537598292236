import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import EventsModal from '../Modals/EventsModal'; 
import '../sidebar.css'; 
import './pages.css';
import { FaEye, FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

const EventsDash = ({ title, Ip,isSuper,isEditor}) => {
  const [Events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select an option');
  const [searchText, setSearchText] = useState('');

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  useEffect(() => {
    if (selectedOption !== 'Select an option') {
      fetchEvents();
    }
  }, [selectedOption]);

  useEffect(() => {
    const filtered = Events.filter(event => 
      event.title.toLowerCase().includes(searchText.toLowerCase()) ||
      event.description.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredEvents(filtered);
  }, [searchText, Events]);

  const fetchEvents = async () => {
    let params = {};
    switch (selectedOption) {
      case 'GALLERY':
        params = { Action: 'gallery' };
        break;
      case 'YOUCAT':
        params = { Action: 'youcat' };
        break;
      default:
        params = {};
        break;
    }

    try {
      const response = await axios.get(`${Ip}Controllers/events.php`, {
        params: params,
        headers: { 'Content-Type': 'application/json' }
      });
      setEvents(response.data);
      setFilteredEvents(response.data);
    } catch (error) {
      console.error('Error fetching Events:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, event = null) => {
    setModalType(type);
    setModalData(event);
    setShowModal(true);
  };

  const handleDeleteEvent = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Action', 'Delete');

        await axios.post(`${Ip}Controllers/events.php`, formData);
        fetchEvents();

        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } else {
        Swal.fire('Cancelled', 'Your item is safe :)', 'error');
      }
    } catch (error) {
      console.error('Error deleting Event:', error.response ? error.response.data : error.message);
      Swal.fire('Error!', 'There was an error deleting the event item.', 'error');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const columns = [
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
    },
    {
      name: "Image",
      cell: row => {
        const imgPathFromDatabase = row.img;
        let correctedImagePath;
        if (imgPathFromDatabase.includes('../../../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../../../assets', 'assets');
        } else if (imgPathFromDatabase.includes('../../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../../assets', 'assets');
        } else if (imgPathFromDatabase.includes('../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../assets', 'assets');
        } else {
          correctedImagePath = imgPathFromDatabase; 
        }
        const image = `${Ip}${correctedImagePath}`;
        return <img src={image} alt={row.title} style={{ width: '20%' }} />;
      }
    },
    {
      name: "Description",
      selector: row => row.description,
      sortable: true,
    },
    {
      name: "Actions",
      cell: row => (
        <div className="button-group">
          <button className="btn section_btn" onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          <button className="btn section_btn" onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
            <FaEdit />
          </button>
          {(isSuper==='true') && (<button className="btn section_btn" onClick={() => handleDeleteEvent(row.id)} style={{ padding: '5px' }}>
            <FaDeleteLeft />
          </button>
          )}
        </div>
      )
    }
  ];
  

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className="my-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <DropdownButton
            id="dropdown-basic-button"
            title={selectedOption}
            onSelect={handleSelect}
            variant="primary"
            style={{ marginRight: '10px' }}
          >
            <Dropdown.Item eventKey="GALLERY">GALLERY</Dropdown.Item>
            <Dropdown.Item eventKey="YOUCAT">YOUCAT</Dropdown.Item>
          </DropdownButton>
          
          <input
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '300px'
            }}
          />
          
          <button
            style={{
              backgroundColor: '#0120ff',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '30px',
              width: '150px',
            }}
            onClick={() => handleModalOpen('Add')}
          >
            Add
          </button>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={filteredEvents}
        pagination
        highlightOnHover
        striped
      />

      {showModal && (
        <EventsModal
          Ip = {Ip}
          type={modalType}
          data={modalData}
          onClose={handleCloseModal}
          onSave={() => {
            handleCloseModal();
            fetchEvents();
          }}
        />
      )}
    </div>
  );
};

export default EventsDash;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Dropdown, DropdownButton } from 'react-bootstrap';
// import EventsModal from '../Modals/EventsModal'; 
// import '../sidebar.css'; 
// import './pages.css';
// import { FaEye } from "react-icons/fa";
// import { FaEdit } from "react-icons/fa";
// import { FaDeleteLeft } from "react-icons/fa6";
// import Swal from 'sweetalert2';

// const EventsDash = ({ title }) => {
//   const [Events, setEvents] = useState([]);
//   const [modalData, setModalData] = useState(null);
//   const [modalType, setModalType] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [selectedOption, setSelectedOption] = useState('Select an option');

//   const handleSelect = (eventKey) => {
//     setSelectedOption(eventKey);
//   };

//   useEffect(() => {
//     if (selectedOption !== 'Select an option') {
//       fetchEvents(); 
//     }
//   }, [selectedOption]);  

//   const fetchEvents = async () => {
//     let params = {};
//     switch (selectedOption) {
//       case 'GALLERY':
//         params = { Action: 'gallery' };
//         break;
//       case 'YOUCAT':
//         params = { Action: 'youcat' };
//         break;
//       default:
//         params = {};
//         break;
//     }

//     try {
//       const response = await axios.get(`${Ip}Controllers/events.php`, {
//         params: params,
//         headers: { 'Content-Type': 'application/json' }
//       });
//       setEvents(response.data);
//     } catch (error) {
//       console.error('Error fetching Events:', error.response ? error.response.data : error.message);
//     }
//   };

//   const handleModalOpen = (type, heroes = null) => {
    
//     setModalType(type);
//     setModalData(heroes);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setModalType('');
//     setModalData(null);
//   };


//   const handleDeleteHero = async (id) => {
//     try {
//       const result = await Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!"
//       });
  
//       if (result.isConfirmed) {
//         const formData = new FormData();
//         formData.append('id', id);
//         formData.append('Action', 'Delete');
  
//         await axios.post(`${Ip}Controllers/events.php`, formData);
//         fetchEvents();

//         Swal.fire(
//           'Deleted!',
//           'Your item has been deleted.',
//           'success'
//         );
//       } else {
//         Swal.fire(
//           'Cancelled',
//           'Your item is safe :)',
//           'error'
//         );
//       }
//     } catch (error) {
//       console.error('Error deleting Event:', error.response ? error.response.data : error.message);
//       Swal.fire(
//         'Error!',
//         'There was an error Event the hero item.',
//         'error'
//       );
//     }
//   };
//   return (
//     <div className="news-page">
//       <div>
//         <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
//       </div>
//       <div className="my-3 addBtn" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
//         <DropdownButton
//             id="dropdown-basic-button"
//             title={selectedOption}
//             onSelect={handleSelect}
//             variant="primary"
//             style={{ marginRight: '10px' }} 
//         >
//             <Dropdown.Item eventKey="GALLERY">GALLERY</Dropdown.Item>
//             <Dropdown.Item eventKey="YOUCAT">YOUCAT</Dropdown.Item>
//         </DropdownButton>
//         <button
//             style={{
//             backgroundColor: '#0120ff',
//             color: 'white',
//             border: 'none',
//             padding: '10px 20px',
//             textAlign: 'center',
//             textDecoration: 'none',
//             fontSize: '16px',
//             borderRadius: '30px',
//             width: '150px',
//             }}
//             onClick={() => handleModalOpen('Add')}
//         >
//             Add
//         </button>
//         </div>

//       <div className="lht_table">
//         <table className="table table-hover" style={{ tableLayout: 'fixed', textAlign: 'left' }}>
//           <thead>
//             <tr>
//               <th style={{ width: '10%', textAlign: 'left' }}>Title</th>
//               <th style={{ width: '15%', textAlign: 'left' }}>Description</th>
//               <th style={{ width: '30%', textAlign: 'left' }}>Images</th>
//               <th style={{ width: '10%', textAlign: 'left' }}>Category</th>
//               <th style={{ width: '10%', textAlign: 'left' }}>Memorable</th>
//               <th style={{ width: '25%', textAlign: 'left' }}>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Events.length ? (
//               Events.map(life => {
//                 let images = [];
//                 try {
//                   images = JSON.parse(life.multiple_img);
//                   if (!Array.isArray(images)) {
//                     throw new Error("Parsed JSON is not an array");
//                   }
//                 } catch (e) {
//                   console.error("Error parsing images JSON:", e);
//                   images = [];
//                 }
//                 return (
//                   <tr key={life.id}>
//                     <td>{life.title}</td>
//                     <td>{life.description}</td>
//                     <td>
//                       {images.map((img, idx) => {
//                         let imagPath = img;
//                         if (imagPath.startsWith("../../../assets")) {
//                           imagPath = img.replace("../../assets", "assets");
//                         } else if (imagPath.startsWith("../assets")) {
//                           imagPath = img.replace("../assets", "assets");
//                         }
//                         const correctedImagePath = `${Ip}${imagPath}`;

//                         return (
//                           <img key={idx} src={correctedImagePath} alt={`Image ${idx}`} style={{ width: '20%', padding: '5px' }} />
//                         );
//                       })}
//                     </td>
//                     <td>{life.category}</td>
//                     <td>{life.memorable}</td>
//                     <td className="button-group">
//                       <button className="btn section_btn" onClick={() => handleModalOpen('View', life)} style={{ padding: '5px' }}>
//                         <FaEye />
//                       </button>
//                       <button className="btn section_btn" onClick={() => handleModalOpen('Edit', life)} style={{ padding: '5px' }}>
//                         <FaEdit />
//                       </button>
//                       <button className="btn section_btn" onClick={() =>handleDeleteHero(life.id)} style={{padding:'5px'}}>
//                         <FaDeleteLeft/>
//                       </button>
//                     </td>
//                   </tr>
//                 );
//               })
//             ) : (
//               <tr><td colSpan="7">No data available</td></tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {showModal && (
//         <EventsModal
//           type={modalType}
//           data={modalData}
//           onClose={handleCloseModal}
//           onSave={() => {
//             handleCloseModal();
//             fetchEvents();
//           }}
//         />
//       )}
//     </div>
//   );
// };

// export default EventsDash;
