import React, { useState } from 'react'
import './Hero.css'
import LibrarySymbol from '../../../assets/img/librarySymbol.png'
import LibBlue from '../../../assets/img/libBlue.png'
import RWL from '../../../assets/img/RWL.png'

const Hero = ({isSelected, setIsSelected, handleClick}) => {


  return (
    <>
      <div className='position-relative library_hero'>
        <div className='position-absolute bottom-0' style={{left: '8%'}}>
            <img src={LibrarySymbol} alt="" className='w-75'/>
        </div>
        <div className='position-absolute w-100' style={{left: '23%', bottom: '15%'}}>
          <img src={RWL} alt="" className='w-20'/>
        </div>
      </div>
      <div className='position-absolute end-0' style={{top: '11%', width: '12%'}}>
        <img src={LibBlue} alt="" className='w-100'/>
      </div>
      <div className='bg_secondary d-flex flex-row align-items-center justify-content-center' style={{height: '100px'}}>
        <div className="d-flex flex-row justify-content-around align-items-center container" style={{width: '90%'}}>
          <h4 className={`text-uppercase text_primary text-white fw-bold`} style={{fontSize: '2.5rem'}}>Library</h4>
          <h4 onClick={() => handleClick('podcasts')} className={`text-uppercase text_primary ${isSelected === 'podcasts' ? 'active' : ''}`}>Podcasts</h4>
          <h4 onClick={() => handleClick('ainoto')} className={`text-uppercase text_primary ${isSelected === 'ainoto' ? 'active' : ''}`}>AÏnoto</h4>
          <h4 onClick={() => handleClick('heritage')} className={`text-uppercase text_primary ${isSelected === 'heritage' ? 'active' : ''}`}>Heritage</h4>
        </div>
      </div> 
    </>
  )
}

export default Hero
