import {React,useState,useEffect} from 'react';
import axios from 'axios';
import './About.css'

const About = ({Ip}) => {
  const [about, setAbout] = useState([]);
  useEffect(() =>{
    fetchAbout();
  },[]);
  const fetchAbout = async () => {
    try {
        const response = await axios.get(`${Ip}Controllers/youcatarabic.php`,{
    });
        // console.log("Fetched History:", response.data);   
        // console.log("Is array history:", Array.isArray(response.data));
        setAbout(response.data);
    } catch (error) {
        console.error('Error fetching History:', error.response ? error.response.data : error.message);
    }
  }

  return (
    <div className='YouCatAbout_container'>
        <div className="container">
            <div className="d-flex flex-column align-items-center">
                <h1 className='text-black fw-bold text-uppercase'>youcat Arabic</h1>
                <hr size='10'/>
            </div>
            {about.map((abt,index)=>(
            <div className="d-flex flex-row align-items-top justify-content-between gap-4 my-5" key={index}>
                <div className="w-30">
                {/* <p style={{fontSize: '1rem'}} className='text-start text-black'>“SPEED تُفيد/ض” (“SPEED use/full) is the motto of the Mission Youth Days organized by the Youcat Lebanon team in partnership with the Pastoral Youth Ministry in the Maronite Patriarchal Curia - BkerkeJeune - and in collaboration with young people from all Catholic Churches.</p> */}
                <p style={{fontSize: '1rem'}} className='text-start text-black'>{abt.description1}</p>
                </div>
                <div className="w-30">
                {/* <p style={{fontSize: '1rem'}} className='text-start text-black'>The essence of the project is based on reaching out to the youth of the peripheral parishes to give them an injection of motivation and a dose of aspiration and Hope in light of the difficult economic and living conditions they are going through by organizing spiritual and recreational meetings with them wherever they are.</p> */}
                <p style={{fontSize: '1rem'}} className='text-start text-black'>{abt.description2}</p>
                </div>
                <div className="w-30">
                <p style={{fontSize: '1rem'}} className='text-start text-black'>{abt.description3}</p>
                {/* <p style={{fontSize: '1rem'}} className='text-start text-black'>This activity will be an opportunity for Lebanon’s youth to dive into the theme of the World Youth Days in Lisbon “Mary arose and went in haste” (Luke 1:39) in a distinguished fraternal atmosphere on the human and spiritual levels.</p> */}
                </div>
            </div>
            )
          )}
        </div>
    </div>
  )
}

export default About
