import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import MyEditor from './MyEditor';
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
// import './button.css';

const Parchment = Quill.import('parchment');
const LineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1', '1.5', '2', '2.5', '3'] 
});
Quill.register(LineHeightStyle, true);

const MembersModal = ({ type, data, onClose, onSave,registred}) => {
    const [fullname, setFullName] = useState(data?.fullname || '');
    const [email, setEmail] = useState(data?.email || '');
    const [phone, setPhone] = useState(data?.phonenumber || '');
    const [address, setAddress] = useState(data?.address || '');
    const [introduction, setIntroduction] = useState(data?.introduction || '');
    const [events, setEvents] = useState(data?.events || '');

  const [error, setError] = useState("");
  //   console.log('Incoming data for HeroModal:', data);
  //   console.log('Current button state:', data.button);
  let buttons = [];
  try {
    if (data && data.button) {
      buttons = JSON.parse(data.button);
    } else {
      buttons = [{ text: "", link: "/admin" }];
    }
  } catch (e) {
    console.error("Error parsing button JSON:", e);
  }
  const [buttons1, setButtons1] = useState(buttons);

  const handleTextChange = (e, index) => {
    const newButtons = [...buttons];
    newButtons[index].text = e.target.value;
    setButtons1(newButtons);
    // sendUpdatedButtons(newButtons);
  };

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      [{ 'lineheight': ['1', '1.5', '2', '2.5', '3'] }],
      ["bold", "italic", "underline", "strike"]
    ]
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "lineheight"
  ];

  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          View
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the title"
              value={fullname}
              onChange={(e) => setFullName(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the title"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the title"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the title"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>
          {registred === 'true' && (
            <Form.Group controlId="formIntro">
                <Form.Label>Event</Form.Label>
                <Form.Control
                type="text"
                value={events}
                onChange={(e) => setEvents(e.target.value)}
                readOnly={type === "View"}
                />
            </Form.Group>
            )}
          <Form.Group controlId="formIntro">
            <Form.Label>Introduction</Form.Label>
            <Form.Control
              type="text"
              value={introduction}
              onChange={(e) => setIntroduction(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>
          {/* <Form.Group controlId="formEvents">
            <Form.Label>Events</Form.Label>
            <Form.Control
              type="text"
              value={events}
              onChange={(e) => setEvents(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group> */}
          {error && <div className="text-danger mt-2">{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MembersModal;
