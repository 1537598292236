import React from 'react'
import './Hero.css'
import heroYouCatSymbol from '../../../assets/img/circlePart1.png'
import Circle2 from '../../../assets/img/circlePart2.png'


const Hero = () => {
 
  return (
    <>
      <div className='position-relative Contact Contactbg'>
        <div className="position-absolute symbolImg">
            <img src={heroYouCatSymbol} alt="" />
        </div>
        <div className="position-absolute symbolImg3">
            <img src={Circle2} alt="" />
        </div>
      </div>
      <div className='position-relative  Contact' style={{top: '-4rem'}}>
        <div className='bar1 d-flex flex-row align-items-center justify-content-center' style={{backgroundColor : '#0120FF'}}></div> 
        <div className='bar2 bg_primary d-flex flex-row align-items-center justify-content-start text-uppercase' style={{wordSpacing: '1rem'}} >
          <div className='d-flex flex-row justify-content-start w-75 px-5' style={{gap: '5rem'}}>
            <h3>Get in Touch</h3>    
            <h3>Drop Us A Line</h3>    
          </div>
          <h3>Drop By</h3>    
        </div> 
      </div>
    </>
  )
}

export default Hero
