import React, { useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "./Modals.css";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

function JoinModal({
  showModal,
  handleModalClose,
  title,
  submitText,
  actionKey,
  Ip
}) {
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
    introduction: "",
    acceptTerms: false,
  });
  // const countries = [
  //   { name: "United States", code: "US", flag: "🇺🇸", dialCode: "+1" },
  //   { name: "Canada", code: "CA", flag: "🇨🇦", dialCode: "+1" },
  //   { name: "United Kingdom", code: "GB", flag: "🇬🇧", dialCode: "+44" },
  //   { name: "Lebanon", code: "LB", flag: "🇱🇧", dialCode: "+961" },
  //   { name: "Egypt", code: "EG", flag: "🇪🇬", dialCode: "+20" },
  //   { name: "Jordan", code: "JO", flag: "🇯🇴", dialCode: "+962" },
  //   { name: "Iraq", code: "IQ", flag: "🇮🇶", dialCode: "+964" },
  //   { name: "Saudi Arabia", code: "SA", flag: "🇸🇦", dialCode: "+966" },
  //   { name: "United Arab Emirates", code: "AE", flag: "🇦🇪", dialCode: "+971" },
  //   { name: "Kuwait", code: "KW", flag: "🇰🇼", dialCode: "+965" },
  //   { name: "Oman", code: "OM", flag: "🇴🇲", dialCode: "+968" },
  //   { name: "Qatar", code: "QA", flag: "🇶🇦", dialCode: "+974" },
  //   { name: "Bahrain", code: "BH", flag: "🇧🇭", dialCode: "+973" },
  //   { name: "Syria", code: "SY", flag: "🇸🇾", dialCode: "+963" },
  // ];

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form Data:", formData);
    handleModalClose();
  };

  const handleAdd = async () => {
    const { fullName, email, phoneNumber, address, introduction, acceptTerms } =
      formData;
    if (
      !fullName ||
      !email ||
      !phoneNumber ||
      !address ||
      !introduction ||
      !acceptTerms
    ) {
      setError("Please fill all required fields!");
      return;
    }
    setError("");

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit the registration form?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, register me!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          // formData.append('title', title);
          formData.append("fullName", fullName);
          formData.append("email", email);
          formData.append("phonenumber", phoneNumber); 
          formData.append("address", address);
          formData.append("introduction", introduction);
          formData.append("acceptterms", acceptTerms ? "1" : "0");
          formData.append("Action", "join");

          const response = await axios.post(
            `${Ip}Controllers/join.php`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          console.log("User joined :", response.data);
          sendMail(formData, response);
        } catch (error) {
          console.error(
            "Error joining the user:",
            error.response ? error.response.data : error.message
          );
          setError("Failed to save hero");
        }
      } else {
        Swal.fire(
          "Cancelled",
          "Your registration has been cancelled.",
          "error"
        );
      }
    });
  };
  // let phpMailerLocation = 'http://localhost/BkerkeBack/'
  let phpMailerLocation = 'http://bkerkejeune.org/'
  const sendMail = async (formData, response) => {
    try {
      const response = await axios.post(
          `${phpMailerLocation}Phpmailer.php`,
          formData, 
          {
              headers: { "Content-Type": "multipart/form-data" },
          }
      );
      console.log("Mail sent successfully:", response.data);
  } catch (error) {
      console.error(
          "Error sending mail:",
          error.response ? error.response.data : error.message
      );
  }
};

  return (
    <>
      <Modal show={showModal} className="modalClass" onHide={handleModalClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title className="text-uppercase text_primary">
            <h3>{title}</h3>
          </Modal.Title>
          <form onSubmit={handleSubmit}>
          {error && <div className='alert alert-danger'>{error}</div>}
            <div className="d-flex flex-column flex-lg-row gap-5">
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="Full Name..."
                className="form-control"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email..."
                className="form-control"
                required
              />
            </div>
            <div className="d-flex flex-column flex-lg-row gap-5 my-5">
              {/* <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number..."
                className="form-control"
                required
              /> */}
              <PhoneInput
                country={"lb"} 
                value={formData.phoneNumber}
                onChange={(phone) => handleChange({ target: { name: 'phoneNumber', value: phone } })}
                // inputClass="form-control"
                placeholder="Phone Number..."
                required
                className="form-control-phone"
                style={{width: "100%", borderBottom:'none!important'}}
                dropdownClass="country-dropdown"
                disableDropdown={false}
              />
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address..."
                className="form-control"
                required
              />
            </div>
            <div>
              <textarea
                name="introduction"
                value={formData.introduction}
                onChange={handleChange}
                className="form-control w-100"
                placeholder="Introduce Yourself Briefly"
                rows="6"
                required
              ></textarea>
            </div>
            <div className="mt-5">
              <Link type="submit" onClick={handleAdd} className="btn_modal">
                {submitText}
              </Link>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer style={{ border: "none!important" }}>
          <div className="d-flex flex-row gap-2 w-100">
            <input
              type="checkbox"
              id="termsRules"
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleChange}
              required
            />
            <label htmlFor="termsRules">
              I accept{" "}
              <span className="fw-bold">
                Terms of Service and Privacy Policy
              </span>
            </label>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JoinModal;
