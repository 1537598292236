import {React,useEffect,useState} from 'react';
import axios from 'axios';
import './Highlight.css'
// import HighlightContent from './HighlightContent'
import { Link } from 'react-router-dom'
import { FaChevronRight } from "react-icons/fa";
import { Fade } from 'react-awesome-reveal';

const Highlight = ({Ip}) => {
    const [highlight, setHighlight] = useState([]);

    useEffect(() => {
        fetchHighlight();
      }, []);
    
    const fetchHighlight = async () => {
        try {
          const response = await axios.get(`${Ip}Controllers/highlights.php`, {
            params: {
              'Action':'View'
           },
            headers: { 'Content-Type': 'application/json' }
          });
          setHighlight(response.data);
        //   console.log("Fetched Highlights:", response.data);
        } catch (error) {
          console.error('Error fetching Highlights:', error.response ? error.response.data : error.message);
        }
    };
    
  return (
    <div className='p-5 ' style={{background: '#F2F2F2'}}>
        <div style={{marginBottom: '5rem'}}>
            <h1 className='text_primary fw-bold my-5 moon-font'>THE HIGHLIGHTS OF THE SEASON</h1>
        </div>
        <div className="container">
            <div className='highlight_cards row'>
                {highlight.map((content, index) => {
                    const img = content.img;
                    let imagPath;
                      if (img.includes('../../../assets')) {
                        imagPath = img.replace('../../../assets', 'assets');
                    } else if (img.includes('../../assets')) {
                      imagPath = img.replace('../../assets', 'assets');
                    } else if (img.includes('../assets')) {
                      imagPath = img.replace('../assets', 'assets');
                    }  else {
                      imagPath = img; 
                    }
                    const correctedImagePath = `${Ip}${imagPath}`;
                    // let bgColor = {};
                    // if (typeof content.bg_color === 'string') {
                    // try {
                    //     bgColor = JSON.parse(content.bg_color.replace(/(\w+):/g, '"$1":')); 
                    //     console.log(bgColor);
                    // } catch (e) {
                    //     console.error("Error parsing bg_color:", e);
                    // }
                    // } else {
                    // bgColor = content.bg_color; 
                    // }
                    return(
                    <div className="col-lg-4 col-md-12 d-flex flex-column gap-2" key={index}>
                        <Fade cascade>
                            <div style={{backgroundColor: '#dedede' }}>
                                <img src={correctedImagePath} alt="" className='w-100 h-auto imgBlock '/>
                            </div>
                            <div className="highlight-body text-start p-5 px-3 bg-white">
                                <h4 className='text_primary mb-0 fw-bold' dangerouslySetInnerHTML={{__html:content.title}}></h4>
                                <p dangerouslySetInnerHTML={{__html:content.description}}></p>
                                <Link to={content.link} className='d-flex flex-row align-items-center gap-2 text_secondary fw-bold highlight_btn'>{content.linktext} <FaChevronRight size='15' fill='#797979'/></Link>
                            </div>
                        </Fade>
                    </div>
                    );
            })}
                
            </div>
        </div>
    </div>
  )
}

export default Highlight