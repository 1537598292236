import React from 'react'
import './Hero.css'
import heroYouCatSymbol from '../../../assets/img/heroYouCatSymbol.png'
import YoucatHero from '../../../assets/img/youcatHero.png'


const Hero = () => {
 
  return (
    <>
      <div className='position-relative youCat_hero' style={{}}>
        <div className="position-absolute symbolImg">
            <img src={heroYouCatSymbol} alt="" />
        </div>
        <div className="position-absolute youcatImg">
            <img src={YoucatHero} alt="" />
        </div>
        <div className="position-absolute youcatImgBg">
            <img src={heroYouCatSymbol} alt="" />
        </div>
      </div>
      <div className='position-relative' style={{top: '-4rem'}}>
        <div className='bar1 d-flex flex-row align-items-center justify-content-center' style={{backgroundColor : '#A9A9AA'}}></div> 
        <div className='bar2 bg_primary d-flex flex-row align-items-center justify-content-around' >
            <h3>YOUCAT ARABIC</h3>    
            <h3>YOUCAT ARABIC</h3>    
            <h3>YOUCAT ARABIC</h3>    
        </div> 
      </div>
    </>
  )
}

export default Hero
