import {React,useEffect,useState} from 'react'
import axios from 'axios';
import './BookHighlight.css'
// import HighlightBook from '../../../assets/img/highlight_book.png'
import { Link } from 'react-router-dom'
import Fade from 'react-awesome-reveal';
import BookModal from '../../Modal/BookModal';


const BookHighlight = ({showModal, handleModalClose, handleModalShow, Ip}) => {
    const [newBook, setNewBook] = useState([]);
    useEffect(() => {
        fetchNewBook();
      }, []);
    
    const fetchNewBook = async () => {
        try {
          const response = await axios.get(`${Ip}Controllers/newbook.php`, {
            params: {
                'Action':'View'
            },
            headers: { 'Content-Type': 'application/json' }
          });
          setNewBook(response.data);
        //   console.log("Fetched NewBook:", response.data);
        } catch (error) {
          console.error('Error fetching NewBook:', error.response ? error.response.data : error.message);
        }
    };
    return (
        <div className='bg_grey-book'>
            <div className="container">
                <div className="row p-4">
                    <div className="col-lg-4 book_title-section col-md-12 d-flex flex-column justify-content-center highlight_title">
                        <div className="d-flex flex-column align-items-center">
                            <div className='top-halo d-flex flex-row align-items-center justify-content-center gap-4 mb-4'>
                                <span></span><span></span><span></span><span></span><span></span>
                            </div>
                            <Fade cascade>
                                <h2 className='text-item m-0 fw-bold text_primary moon-font'>LAUNCHING</h2>
                                <h3 className='text-item m-0 fw-bold text_primary my-3'>OUR NEW</h3>
                                <h3 className='text-item m-0 fw-bold text_secondary moon-font' >BOOK</h3>
                            </Fade>
                            <div className='bottom-halo d-flex flex-row align-items-center justify-content-center gap-4 mt-4'>
                                <span></span><span></span><span></span><span></span><span></span>
                            </div>
                        </div>
                    </div>
                    {newBook.map((content, index) => {
                        const img = content.img;
                        let imagPath;
                        if (img.includes('../../../assets')) {
                            imagPath = img.replace('../../../assets', 'assets');
                        } else if (img.includes('../../assets')) {
                            imagPath = img.replace('../../assets', 'assets');
                        }else if (img.includes('../assets')) {
                            imagPath = img.replace('../assets', 'assets');
                        } else {
                            imagPath = img; 
                        }
                        const correctedImagePath = `${Ip}${imagPath}`;
                    return(
                    <div className='book_item col-lg-8 col-md-12 mt-5 d-flex position-relative' key={index} >
                        <img src={correctedImagePath} className='h-100 position-relative' alt="" />
                        <div>
                            <Link to={'/'} className="btn btn_primary text_secondary copy_btn" onClick={handleModalShow}>GET A COPY</Link>
                        </div>
                        <BookModal Ip={Ip} showModal= {showModal} bookImg={correctedImagePath} bookTitle={content.title} bookId={content.id} handleModalClose= {handleModalClose} title="Order a copy" submitText="Order a copy" actionKey = 'Book'/>
                    </div>
                    )
                    })}
                </div>
            </div>

        </div>
    )
}

export default BookHighlight
