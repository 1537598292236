import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeroModal from '../Modals/HeroModal'; 
import '../sidebar.css'; 
import './pages.css';
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';

const HeroDash = ({ title, Ip,isEditor,isSuper }) => {
  const [heroes, setHeroes] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchHero();
  }, []);

  const fetchHero = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/hero.php`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setHeroes(response.data);
    } catch (error) {
      console.error('Error fetching Hero:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, heroes = null) => {
    setModalType(type);
    setModalData(heroes);
    setShowModal(true);
  };

  const handleDeleteHero = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });
  
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Action', 'Delete');
  
        await axios.post(`${Ip}Controllers/hero.php`, formData);
        fetchHero();

        Swal.fire(
          'Deleted!',
          'Your item has been deleted.',
          'success'
        );
      } else {
        Swal.fire(
          'Cancelled',
          'Your item is safe :)',
          'error'
        );
      }
    } catch (error) {
      console.error('Error deleting Hero:', error.response ? error.response.data : error.message);
      Swal.fire(
        'Error!',
        'There was an error deleting the hero item.',
        'error'
      );
    }
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const columns = [
    {
      name: 'Title',
      selector: row => row.title.replace(/<\/?[^>]+(>|$)/g, ""),
      sortable: true,
      style: { width: '25%' },
    },
    {
      name: 'Published Date',
      selector: row => row.date,
      sortable: true,
      style: { width: '15%' },
    },
    {
      name: 'SubTitle',
      selector: row => row.subtitle,
      sortable: true,
      style: { width: '20%' },
    },
    {
      name: 'Image',
      selector: row => {
        let correctedImagePath;
    
        if (row.bg_img.includes('../../../assets')) {
          correctedImagePath = row.bg_img.replace('../.././assets', 'assets');
        }
        else if (row.bg_img.includes('../../assets')) {
          correctedImagePath = row.bg_img.replace('../../assets', 'assets');
        } 
        else if (row.bg_img.includes('../assets')) {
          correctedImagePath = row.bg_img.replace('../assets', 'assets');
        } else {
          correctedImagePath = row.bg_img; 
        }
    
        const image = `${Ip}${correctedImagePath}`;
          return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={image} alt={row.title} style={{ width: '30%'}} />
          </div>
        );
      },
      style: { width: '30%' },
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="d-flex flex-row align-items-center button-group">
          <button className="btn section_btn" title='View' onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          <button className="btn section_btn" title='Edit' onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
            <FaEdit />
          </button>
          {(isSuper==='true') && (<button className="btn section_btn" title='Delete' onClick={() => handleDeleteHero(row.id)} style={{ padding: '5px' }}>
            <FaDeleteLeft />
          </button>
          )}
        </div>
      ),
      style: { width: '10%' },
    },
  ];

  // Filter heroes based on search term
  const filteredHeroes = heroes.filter(hero => {
    return hero.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
           hero.subtitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
           hero.date.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className='d-flex flex-row align-items-center justify-content-between gap-4' style={{width: '75vw'}}>
        <div className="px-5" style={{ width: '100%', paddingTop: '3em', paddingBottom: '0' }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          />
        </div>
        <div className="addBtn">
          <button
            style={{
              backgroundColor: '#0120ff',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '30px',
              width: '150px',
              marginTop: '3em'
              // marginLeft: '90%',
            }}
            onClick={() => handleModalOpen('Add')}
          >
            Add
          </button>
        </div>
      </div>

      

      <div className="lht_table table table-hover" style={{width: '85vw',paddingTop:'30px'}}>
        <DataTable
          columns={columns}
          data={filteredHeroes}
          pagination
          highlightOnHover
          pointerOnHover
          noDataComponent="No data available"
          customStyles={{
            pagination: {
              style: {
                justifyContent: 'flex-end',
              },
            },
          }}
        />
      </div>

      {showModal && (
        <HeroModal 
          Ip = {Ip}
          type={modalType} 
          data={modalData} 
          onClose={handleCloseModal} 
          onSave={() => {
            handleCloseModal();
            fetchHero();
          }} 
        />
      )}
    </div>
  );
};

export default HeroDash;
