import React, { useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import MyEditor from './MyEditor';
import axios from 'axios';
// import './button.css';

const SubHeroModal = ({ type, data, onClose, onSave, Ip }) => {
  const [title, setTitle] = useState(data ? data.title : '');
  const [description, setDescription] = useState(data ? data.description : '');
  const [linkto, setLinkto] = useState(data ? data.linkto : '');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');

  const handleAdd = async () => {
    if (!title || !description) {
      setError('Please enter all required fields!');
      return;
    }
    setError('');
  
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('img', image);
      formData.append('Action', 'Add');

    
      const response = await axios.post(`${Ip}Controllers/subhero.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('subhero saved:', response.data);
      onSave(); 
    } catch (error) {
      console.error('Error saving subhero:', error.response ? error.response.data : error.message);
      setError('Failed to save hero');
    }
  
    onClose();
  };
  
  const handleEdit = async () => {
    if (!title || !description) {
      setError('Please enter all required fields!');
      return;
    }
    setError('');
  
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('img', image);
      formData.append('Action', 'Edit');
  
      const response = await axios.post(`${Ip}Controllers/subhero.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('SubHero updated:', response.data);
      onSave();
    } catch (error) {
      console.error('Error updating SubHero:', error.response ? error.response.data : error.message);
      setError('Failed to save news');
    }
  
    onClose();
  };
    // const handleView = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('id', data.id);
  //     formData.append('Action','View')
      
  //     const response = await axios.post('http://localhost/patriarchdouaihy/src/admin/Controllers/steps.php', formData, {
  //       headers: { 'Content-Type': 'multipart/form-data' }
  //     });
  //     console.log('Success:', response.data);
  //     onSave(); 
  //   } catch (error) {
  //     console.error('Error :', error.response ? error.response.data : error.message);
  //     setError('Failed to view');
  //   }
    
  //   onClose();
  // };

  return (
    <Modal show={type !== ''} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
      <Modal.Title>
        {type === 'Add' ? 'Add' : type === 'Edit' ? 'Edit' : 'View'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>

          <Form.Group controlId="formTitle">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter the description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>
          <Form.Group controlId="formTitle">
            <Form.Label>Link To</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the Subtitle"
              value={linkto}
              onChange={(e) => setLinkto(e.target.value)}
              readOnly={type === 'View'}
            />
          </Form.Group>

          {/* <Form.Group controlId="formDescription" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              placeholder="Enter the description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group> */}
          {/* <Form.Group controlId="formDescription" className="mt-3 border-box">
            <Form.Label>Description</Form.Label>
            <div
              style={{
                maxHeight: '200px', 
                overflowY: 'auto',
                border: '1px solid #ccc', 
                padding: '10px',    
                borderRadius: '5px', 
              }}
            >
              <MyEditor
                value={description}             
                onChange={setDescription}     
                placeholder="Enter the description" 
              />
            </div>
          </Form.Group> */}

          {(type === 'Add' || type === 'Edit')&&(
          <Form.Group controlId="formImage" className="mt-3">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Form.Group>
          )}
          {error && <div className="text-danger mt-2">{error}</div>}
          {type === 'View' && (
          <Form.Group controlId="formDescription" className="mt-3">
            <Form.Label>Image</Form.Label>
            {(() => {
              let correctedImagePath;
                  if (data.icon.includes('../../../assets')) {
                    correctedImagePath = data.icon.replace('../../../assets', 'assets');
                } else if (data.icon.includes('../../assets')) {
                  correctedImagePath = data.icon.replace('../../assets', 'assets');
                }  else if (data.icon.includes('../assets')) {
                    correctedImagePath = data.icon.replace('../assets', 'assets');
                } else {
                    correctedImagePath = data.icon; 
                }
              const image = `${Ip}${correctedImagePath}`;
              return (
                <div>
                  <img src={image} alt={data.title} style={{ maxWidth: '20%', height: 'auto' }} />
                </div>
              );
            })()}
          </Form.Group>
    )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
            Close
        </Button>
        {(type === 'Add' || type === 'Edit') && (
        <Button 
          variant="primary"
          style={{ backgroundColor: '#0120ff', borderColor: '#0120ff' }} 
          onClick={type === 'Add' ? handleAdd : handleEdit}
        >
          {type === 'Add' ? 'Add' : 'Save Changes'}
        </Button>
      )}
      </Modal.Footer>
    </Modal>
  );
};

export default SubHeroModal;
