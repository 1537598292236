import { React, useEffect, useState } from 'react';
import './Hero.css';
import axios from 'axios';
import Slider from "react-slick";
import WebBlue from '../../../assets/img/webBlueFull.png';
import WebGreen from '../../../assets/img/webGreenFull.png';
import { Link } from 'react-router-dom';
import PlaySec from '../../../assets/img/play.png';
import RegisterModal from '../../Modal/RegisterModal';

const Hero = ({showModal, handleModalClose, handleModalShow, Ip}) => {
  const [Heroes, setHeroes] = useState([]);
  const [currentTitle, setCurrentTitle] = useState(''); 
  
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    fetchHeroes();
  }, []);

  const fetchHeroes = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/hero.php`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setHeroes(response.data);
    } catch (error) {
      console.error('Error fetching Heroes:', error.response ? error.response.data : error.message);
    }
  };

  const spans = Array.from({ length: 17 });

  return (
    <>
    <Slider {...settings}>
      {Heroes.map((hero) => {
        const formattedTitle = hero.title.replace(/<\/?p[^>]*>/g, ''); 
        const img = hero.bg_img;
        let imagPath;
        if (img.includes('../../../assets')) {
          imagPath = img.replace('../../../assets', 'assets');
        } else if (img.includes('../../assets')) {
          imagPath = img.replace('../../assets', 'assets');
        } else if (img.includes('../assets')) {
          imagPath = img.replace('../assets', 'assets');
        } else {
          imagPath = img; 
        }
        const correctedImagePath = `${Ip}${imagPath}`;

        let buttons = [];
        try {
          buttons = JSON.parse(hero.button);
        } catch (e) {
          console.error("Error parsing button JSON:", e);
        }

        return (
          <div key={hero.id}>
            <div className='hero_size' style={{ backgroundImage: `url(${correctedImagePath})` }}>
              
                <div style={hero.id === 2 ? {opacity:'1'} : {opacity:'0'}}>
                  <img src={WebBlue} alt="" className='web-group' />
                  <img src={WebGreen} alt="" className='web-group1' />
                </div>
  
              <div className="overlay"></div>
              <div className="empty-container"></div>
              <div className="text-container">
                <h4 className='fw-bold'>{hero.date}</h4>
                <h1 className='text-white fw-bold w-55 m-0' style={{ lineHeight: '2.5rem' }} dangerouslySetInnerHTML={{ __html: hero.title }}></h1>
                {hero.subtitle === 'WMYD 2025' && (
                  <div className="span_container d-flex">
                    {spans.map((_, index) => (
                      <span key={index}></span>
                    ))}
                  </div>
                )}
                <h3 className='text-white mt-4'>{hero.subtitle}</h3>
                <p className='text-white' dangerouslySetInnerHTML={{ __html: hero.description }}></p>

                {Array.isArray(buttons) && buttons.length > 0 ? (
                  buttons
                    .filter((btn) => btn.text && btn.text.trim() !== '') 
                    .map((btn, idx) => (
                      <Link 
                        key={idx} 
                        className='btn btn-primary btn_primary fw-bold' 
                        onClick={() => {
                          setCurrentTitle(formattedTitle); 
                          handleModalShow(); 
                        }}
                      >
                        {btn.text}
                      </Link>
                    ))
                ) : (
                  <p></p>
                )}

                {hero.subtitle === 'WMYD 2025' && (
                  <div className='play-btn'>
                    <img src={PlaySec} alt="Play button" />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
    <RegisterModal Ip={Ip} showModal= {showModal} handleModalClose= {handleModalClose} title="Register" submitText="Register" event_title={currentTitle} actionKey = 'Register'/>
    </>
  );
}

export default Hero;
