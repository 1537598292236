  import React, { useState } from 'react';
  import Dashboard from './Dashboard';
  // import LatestNews from './Pages/LatestNews'; 
  import Hero from './Components/HeroDash';
  import SubHero from './Components/SubHeroDash';
  import BookHighlight from './Components/BookHighlightsDash';
  import Highlight from './Components/HighlightsDash';
  import VisionMission from './Components/VisionMissionDash';
  import OurStoryDash from './Components/OurStoryDash';
  import Objective from './Components/ObjectiveDash';
  import Podcasts from './Components/PodcastsDash';
  import Events from './Components/EventsDash';
  import History from './Components/HistoryDash';
  import Youcat from './Components/YoucatDash';
  import ContactUs from './Components/ContactUsDash';
  import Users from './Components/UsersDash';
  import Members from './Components/MembersDash';
  import Registred from './Components/RegistredMembersDash';
  import BookPurchasersDash from './Components/BookPurchasersDash';
  import MessagesDash from './Components/MessagesDash';
  // import Navbar from './Navbar';
  import Logout from './Components/Logout';
  import useIdleTimeout from './Components/Timeout';
  const MyComponent = ({Ip}) => {
    useIdleTimeout(10 * 60 * 1000);
    const [activeComponent, setActiveComponent] = useState(null);
    const isSuper = localStorage.getItem('isSuper');
    const isEditor = localStorage.getItem('isEditor');

    const renderActiveComponent = () => {
      switch (activeComponent) {
        case 'Hero':
          return <Hero title={"Hero"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'SubHero':
          return <SubHero title={"SubHero"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'BookHighlight':
          return <BookHighlight title={"BookHighlight"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'Highlight':
          return <Highlight title={"Highlights"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'Vision':
          return <VisionMission title={"Vision & Mission"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'OurStory':
          return <OurStoryDash title={"Our Story"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'Objective':
          return <Objective title={"Our Objectives"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'Podcasts':
          return <Podcasts title={"Library"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'Gallery':
          return <Events title={"Events"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'history':
          return <History title={"History"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'youcatarabic':
          return <Youcat title={"Youcat"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'edit':
          return <ContactUs title={"Contact Us"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case 'users':
          return <Users title={"Users"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case'Members':
          return <Members title={"Members"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case'Registred':
          return <Registred title={"Members/Events"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case'purchasers':
          return <BookPurchasersDash title={"Book Purchasers"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        case'messages':
          return <MessagesDash title={"Contact Us Messages"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
        default:
          return <Hero title={"Hero"} Ip = {Ip} isSuper={isSuper} isEditor={isEditor}/>;
      }
    };

    const handleSetActiveComponent = (component) => {
      setActiveComponent(component);
    };

    return (
      <div style={{ position: 'relative' }}>
        {/* <Navbar/> */}
        <Dashboard setActiveComponent={handleSetActiveComponent} />
        <div 
          style={{ 
            position: 'absolute', 
            top: '20px', 
            left: '20.3vw', 
            width: 'calc(100% - 100px)',
            height: '100vh', 
            overflowY: 'auto'
          }}
        >
          {renderActiveComponent()}
        </div>
        <div style={{ position: 'absolute', top: '0px', right: '10px', marginBottom:'50px'}}>
          <Logout />
        </div>
      </div>
    );
  };

  export default MyComponent;
