import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import MyEditor from './MyEditor';
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import './button.css';

const VMModal = ({ type, data, onClose, onSave, Ip }) => {
  const [vision, setVision] = useState(data ? data.vision : "");
  const [mission, setMission] = useState(data ? data.mission : "");
  const [error, setError] = useState("");

  const handleEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("vision", vision);
      formData.append("mission", mission);
      formData.append("Action", "Edit");

      const response = await axios.post(
        `${Ip}Controllers/visionmission.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Vision Mission updated:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error updating Vision Mission:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save news");
    }

    onClose();
  };
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ header: [1, 2, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];
  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{type === "Edit" ? "Edit" : "View"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Vision</Form.Label>
            {/* <Form.Control
                as="textarea"
                rows={4}
                placeholder="Vision"
                value={vision}
                onChange={(e) => setVision(e.target.value)}
                readOnly={type === 'View'}
                /> */}
            <ReactQuill
              value={vision}
              onChange={(value) => setVision(value)}
              modules={modules}
              formats={formats}
              placeholder="Vision"
              theme="snow"
              readOnly={type === "View"}
            />
          </Form.Group>
          <Form.Group controlId="formTitle">
            <Form.Label>Mision</Form.Label>
            {/* <Form.Control
              as="textarea"
              rows={4}
            //   style={{ width: '400px' }}
              placeholder="Mission"
              value={mission}
              onChange={(e) => setMission(e.target.value)}
              readOnly={type === 'View'}
            /> */}
            <ReactQuill
              value={mission}
              onChange={(value) => setMission(value)}
              modules={modules}
              formats={formats}
              placeholder="Mission"
              theme="snow"
              readOnly={type === "View"}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {type === "Edit" && (
          <Button
            variant="primary"
            style={{ backgroundColor: "#0120ff", borderColor: "#0120ff" }}
            onClick={handleEdit}
          >
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default VMModal;
