import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PodcastsModal from '../Modals/PodcastsModal'; 
import '../sidebar.css'; 
import './pages.css';
import { FaEye, FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

const PodcastsDash = ({ title, Ip,isSuper,isEditor }) => {
  const [Podcasts, setPodcasts] = useState([]);
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select a category');
  const [searchText, setSearchText] = useState('');

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  useEffect(() => {
    if (selectedOption !== 'Select a category') {
      fetchPodcasts();
    }
  }, [selectedOption]);

  useEffect(() => {
    const filtered = Podcasts.filter(podcast => 
      podcast.title.toLowerCase().includes(searchText.toLowerCase()) ||
      podcast.description.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredPodcasts(filtered);
  }, [searchText, Podcasts]);

  const fetchPodcasts = async () => {
    let Category = '';
    switch (selectedOption) {
      case 'PODCASTS':
        Category = 'podcasts';
        break;
      case 'AINOTO':
        Category = 'ainoto';
        break;
      case 'HERITAGE':
        Category = 'heritage';
        break;
    }

    try {
      const response = await axios.get(`${Ip}Controllers/podcasts.php?category=${Category}`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setPodcasts(response.data);
      setFilteredPodcasts(response.data);
    } catch (error) {
      console.error('Error fetching Podcasts:', error.response ? error.response.data : error.message);
    }
  };

  const handleModalOpen = (type, podcast = null) => {
    setModalType(type);
    setModalData(podcast);
    setShowModal(true);
  };

  const handleDeleteHero = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Action', 'Delete');

        await axios.post(`${Ip}Controllers/podcasts.php`, formData);
        fetchPodcasts();

        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } else {
        Swal.fire('Cancelled', 'Your item is safe :)', 'error');
      }
    } catch (error) {
      console.error('Error deleting Hero:', error.response ? error.response.data : error.message);
      Swal.fire('Error!', 'There was an error deleting the hero item.', 'error');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType('');
    setModalData(null);
  };

  const columns = [
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      cell: row => <div dangerouslySetInnerHTML={{ __html: row.title }}></div>
    },
    {
      name: "Image",
      cell: row => {
        const imgPathFromDatabase = row.img;
        let correctedImagePath;
        if (imgPathFromDatabase.includes('../../../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../../../assets', 'assets');
        }else if (imgPathFromDatabase.startsWith("../../assets")) {
          correctedImagePath = imgPathFromDatabase.replace("../../assets", "assets");
        }
         else if (imgPathFromDatabase.includes('../assets')) {
          correctedImagePath = imgPathFromDatabase.replace('../assets', 'assets');
        } else {
          correctedImagePath = imgPathFromDatabase;
        }
        const image = `${Ip}${correctedImagePath}`;
        return <img src={image} alt={row.title} style={{ width: '20%' }} />;
      }
    },
    {
      name: "Description",
      selector: row => row.description,
      sortable: true,
    },
    {
      name: "Actions",
      cell: row => (
        <div className="button-group">
          <button className="btn section_btn" onClick={() => handleModalOpen('View', row)} style={{ padding: '5px' }}>
            <FaEye />
          </button>
          <button className="btn section_btn" onClick={() => handleModalOpen('Edit', row)} style={{ padding: '5px' }}>
            <FaEdit />
          </button>
          {(isSuper==='true') && (<button className="btn section_btn" onClick={() => handleDeleteHero(row.id)} style={{ padding: '5px' }}>
            <FaDeleteLeft />
          </button>
            )}
        </div>
      )
    }
  ];

  return (
    <div className="news-page">
      <div>
        <h1 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</h1>
      </div>
      <div className="my-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <DropdownButton
      id="dropdown-basic-button"
      title={selectedOption}
      onSelect={handleSelect}
      variant="primary"
      style={{ marginRight: '10px' }}
    >
      <Dropdown.Item eventKey="PODCASTS">PODCASTS</Dropdown.Item>
      <Dropdown.Item eventKey="AINOTO">AINOTO</Dropdown.Item>
      <Dropdown.Item eventKey="HERITAGE">HERITAGE</Dropdown.Item>
    </DropdownButton>
    
    <input
      type="text"
      placeholder="Search..."
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      style={{
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '300px'
      }}
    />
    
    <button
      style={{
        backgroundColor: '#0120ff',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize: '16px',
        borderRadius: '30px',
        width: '150px',
      }}
      onClick={() => handleModalOpen('Add')}
    >
      Add
    </button>
  </div>
</div>
      <DataTable
        columns={columns}
        data={filteredPodcasts}
        pagination
        highlightOnHover
        striped
      />

      {showModal && (
        <PodcastsModal
          Ip = {Ip}
          type={modalType}
          data={modalData}
          onClose={handleCloseModal}
          onSave={() => {
            handleCloseModal();
            fetchPodcasts();
          }}
        />
      )}
    </div>
  );
};

export default PodcastsDash;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Dropdown, DropdownButton } from 'react-bootstrap';
// import PodcastsModal from '../Modals/PodcastsModal'; 
// import '../sidebar.css'; 
// import './pages.css';
// import { FaEye } from "react-icons/fa";
// import { FaEdit } from "react-icons/fa";
// import { FaDeleteLeft } from "react-icons/fa6";
// import DataTable from 'react-data-table-component';
// import Swal from 'sweetalert2';

// const PodcastsDash = ({ title }) => {
//   const [Podcasts, setPodcasts] = useState([]);
//   const [modalData, setModalData] = useState(null);
//   const [modalType, setModalType] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [selectedOption, setSelectedOption] = useState('Select a category');
//   const handleSelect = (eventKey) => {
//     setSelectedOption(eventKey);
//   };

//   useEffect(() => {
//     if (selectedOption !== 'Select a category') {
//       fetchPodcasts(); 
//     }
//   }, [selectedOption]);  

//   const fetchPodcasts = async () => {
//     let Category = '';
//     switch (selectedOption) {
//       case 'PODCASTS':
//         Category = 'podcasts';
//         break;
//       case 'AINOTO':
//         Category = 'ainoto';
//         break;
//       case 'HERITAGE':
//         Category = 'heritage';
//         break;
//       default:
//         Category = '';
//         break;
//     }

//     try {
//       const response = await axios.get(`${Ip}Controllers/podcasts.php?category=${Category}`, {
//         headers: { 'Content-Type': 'application/json' }
//       });
//       setPodcasts(response.data);
//     } catch (error) {
//       console.error('Error fetching Podcasts:', error.response ? error.response.data : error.message);
//     }
//   }

//   const handleModalOpen = (type, heroes = null) => {
//     setModalType(type);
//     setModalData(heroes);
//     setShowModal(true);
//   };

//   const handleDeleteHero = async (id) => {
//     try {
//       const result = await Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!"
//       });
  
//       if (result.isConfirmed) {
//         const formData = new FormData();
//         formData.append('id', id);
//         formData.append('Action', 'Delete');
  
//         await axios.post(`${Ip}Controllers/podcasts.php`, formData);
//         fetchPodcasts();

//         Swal.fire(
//           'Deleted!',
//           'Your item has been deleted.',
//           'success'
//         );
//       } else {
//         Swal.fire(
//           'Cancelled',
//           'Your item is safe :)',
//           'error'
//         );
//       }
//     } catch (error) {
//       console.error('Error deleting Hero:', error.response ? error.response.data : error.message);
//       Swal.fire(
//         'Error!',
//         'There was an error deleting the hero item.',
//         'error'
//       );
//     }
//   };
  

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setModalType('');
//     setModalData(null);
//   };

//   return (
//     <div className="news-page">
//       <div>
//         <h1 style={{ textAlign:'left',fontSize: '25px', fontWeight: 'bold', marginLeft: '10px'}}>{title}</h1>
//       </div>
//       <div className="my-3 addBtn" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
//       <DropdownButton
//             id="dropdown-basic-button"
//             title={selectedOption}
//             onSelect={handleSelect}
//             variant="primary"
//             style={{ marginRight: '10px' }} 
//         >
//             <Dropdown.Item eventKey="PODCASTS">PODCASTS</Dropdown.Item>
//             <Dropdown.Item eventKey="AINOTO">AINOTO</Dropdown.Item>
//             <Dropdown.Item eventKey="HERITAGE">HERITAGE</Dropdown.Item>
//         </DropdownButton>
//         <button
//             style={{
//             backgroundColor: '#0120ff',
//             color: 'white',
//             border: 'none',
//             padding: '10px 20px',
//             textAlign: 'center',
//             textDecoration: 'none',
//             fontSize: '16px',
//             borderRadius: '30px',
//             width: '150px',
//             }}
//             onClick={() => handleModalOpen('Add')}
//         >
//             Add
//         </button>
//       </div>

//       <div className="lht_table">
//         <table className="table table-hover" style={{ tableLayout: 'fixed',textAlign:'left'}}>
//           <thead>
//             <tr>
//               <th style={{ width: '20%',textAlign:'left'}}>Title</th>
//               <th style={{ width: '15%',textAlign:'left'}}>Image</th>
//               <th style={{ width: '45%',textAlign:'left'}}>Description</th>
//               <th style={{ width: '25%',textAlign:'left'}}>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Podcasts.length ? (
//                 Podcasts.map(life => {
//                   const imgPathFromDatabase = life.img;
//                   let correctedImagePath;
//                   if (imgPathFromDatabase.includes('../../../assets')) {
//                     correctedImagePath = imgPathFromDatabase.replace('../../assets', 'assets');
//                 } else if (imgPathFromDatabase.includes('../assets')) {
//                     correctedImagePath = imgPathFromDatabase.replace('../assets', 'assets');
//                 } else {
//                     correctedImagePath = imgPathFromDatabase; 
//                 }
//                   const image = `${Ip}${correctedImagePath}`;
//                   return (
//                     <tr key={life.id}>
//                     <td dangerouslySetInnerHTML={{__html:life.title}}></td>
//                     <td><img src={image} alt={life.title} style={{ width: '30%' }} /></td>
//                     <td>{life.description}</td>
//                     <td className="button-group">
//                       <button className="btn section_btn" onClick={() => handleModalOpen('View',life)} style={{padding:'5px'}}>
//                         <FaEye/>
//                       </button>
//                       <button className="btn section_btn" onClick={() => handleModalOpen('Edit', life)} style={{padding:'5px'}}>
//                         <FaEdit/>
//                       </button>
//                       <button className="btn section_btn" onClick={() =>handleDeleteHero(life.id)} style={{padding:'5px'}}>
//                         <FaDeleteLeft/>
//                       </button>
//                     </td>
//                   </tr>
//                 );
//               })
//             ) : (
//               <tr><td colSpan="5">No data available</td></tr> 
//             )}
//           </tbody>
//         </table>
//       </div>

//       {showModal && (
//         <PodcastsModal 
//           type={modalType} 
//           data={modalData} 
//           onClose={handleCloseModal} 
//           onSave={() => {
//             handleCloseModal();
//             fetchPodcasts();
//           }} 
//         />
//       )}
//     </div>
//   );
// };

// export default PodcastsDash;
