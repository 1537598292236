import React, { useState } from 'react';

function Test() {
  const [name, setName] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to send
    const dataToSend = { name: name };

    fetch('http://localhost/bkerkeyouth/admin/myscript.php', {
      method: 'POST', // Use POST to send data
      headers: {
        'Content-Type': 'application/json', // Specify the content type as JSON
      },
      body: JSON.stringify(dataToSend), // Send the data as JSON
    })
      .then((response) => response.json())
      .then((data) => {
        setResponseMessage(data.message); // Handle response from PHP
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <h1>Send Data to PHP</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <button type="submit">Send</button>
      </form>

      {responseMessage && <p>Response from PHP: {responseMessage}</p>}
    </div>
  );
}

export default Test;