import React, { useState, useEffect } from "react";
import { Modal, Button, Form,InputGroup  } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import MyEditor from './MyEditor';
import axios from "axios";
// import './button.css';

const BookHighlightsModal = ({ type, data, onClose, onSave, Ip }) => {
  const [title, setTitle] = useState(data ? data.title : "");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState(data ? data.link : "");
  const [error, setError] = useState("");
  const [price, setPrice] =useState(data ? data.price :"");
  const [quantity, setQuantity] = useState(data ? data.qty:"");

  const handleAdd = async () => {
    if (!title) {
      setError("Please enter all required fields!");
      return;
    }
    setError("");

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("img", image);
      formData.append("link", link);
      formData.append("price", price);
      formData.append("qty", quantity);
      formData.append("Action", "Add");

      const response = await axios.post(
        `${Ip}Controllers/newbook.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("book highlights saved:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error saving book highlights:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save hero");
    }

    onClose();
  };

  const handleEdit = async () => {
    if (!title) {
      setError("Please enter all required fields!");
      return;
    }
    setError("");

    try {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("title", title);
      formData.append("img", image);
      formData.append("link", link);
      formData.append("price", price);
      formData.append("qty", quantity);
      formData.append("Action", "Edit");

      const response = await axios.post(
        `${Ip}Controllers/newbook.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("book highlights updated:", response.data);
      onSave();
    } catch (error) {
      console.error(
        "Error updating book highlights:",
        error.response ? error.response.data : error.message
      );
      setError("Failed to save news");
    }

    onClose();
  };
  return (
    <Modal show={type !== ""} onHide={onClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "Add" ? "Add" : type === "Edit" ? "Edit" : "View"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            readOnly={type === "View"}
          />
        </Form.Group>
        <Form.Group controlId="formPrice">
          <Form.Label>Price</Form.Label>
          <InputGroup>
            <Form.Control
              type="number" 
              placeholder="Enter the price in $"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              readOnly={type === "View"}
            />
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formQuantity">
          <Form.Label>Quantity</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter the Quantity ..."
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            readOnly={type === "View"}
          />
        </Form.Group>
          <Form.Group controlId="formTitle">
            <Form.Label>Link To</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              readOnly={type === "View"}
            />
          </Form.Group>

          {(type === "Add" || type === "Edit") && (
            <Form.Group controlId="formImage" className="mt-3">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Form.Group>
          )}
          {error && <div className="text-danger mt-2">{error}</div>}
          {(type === "View" || type === "Edit") && (
            <Form.Group controlId="formDescription" className="mt-3">
              <Form.Label>Image</Form.Label>
              {(() => {
                let correctedImagePath;
                if (data.img.includes("../../assets")) {
                  correctedImagePath = data.img.replace(
                    "../../assets",
                    "assets"
                  );
                } else if (data.img.includes("../assets")) {
                  correctedImagePath = data.img.replace("../assets", "assets");
                } else {
                  correctedImagePath = data.img;
                }
                const image = `${Ip}${correctedImagePath}`;
                return (
                  <div>
                    <img
                      src={image}
                      alt={data.title}
                      style={{ maxWidth: "20%", height: "auto" }}
                    />
                  </div>
                );
              })()}
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {(type === "Add" || type === "Edit") && (
          <Button
            variant="primary"
            style={{ backgroundColor: "#0120ff", borderColor: "#0120ff" }}
            onClick={type === "Add" ? handleAdd : handleEdit}
          >
            {type === "Add" ? "Add" : "Save Changes"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BookHighlightsModal;
