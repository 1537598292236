import {React,useEffect,useState} from 'react';
import axios from 'axios';
import './Story.css'
import storyBtm from '../../../assets/img/storyBtm.png'
import BgGreen from '../../../assets/img/bg-green.png'
import { BiChevronRight } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { AttentionSeeker } from 'react-awesome-reveal';
import Fade from 'react-awesome-reveal'

const Story = ({Ip}) => {
  const [VM, setVM] = useState([]);
  const [stories, setStories] = useState([]);
  const [objectives, setObjectives] = useState([]);
  useEffect(() => {
    fetchStory();
    fetchVM();
    fetchObjectives();
  }, []);

const fetchStory = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/ourstory.php`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setStories(response.data);
    //   console.log("Fetched Story:", response.data);
    } catch (error) {
      console.error('Error fetching Story:', error.response ? error.response.data : error.message);
    }
};  
const fetchObjectives = async () => {
    try {
      const response = await axios.get(`${Ip}Controllers/ourobjective.php`, {
        headers: { 'Content-Type': 'application/json' }
      });
      setObjectives(response.data);
    //   console.log("Fetched Objectives:", response.data);
    } catch (error) {
      console.error('Error fetching Objective:', error.response ? error.response.data : error.message);
    }
};  
    const fetchVM = async () => {
        try {
          const response = await axios.get(`${Ip}Controllers/visionmission.php`, {
            headers: { 'Content-Type': 'application/json' }
          });
          setVM(response.data);
        //   console.log("Fetched VM:", response.data);
        } catch (error) {
          console.error('Error fetching VM:', error.response ? error.response.data : error.message);
        }
      };

  // split the paragraphs functions {
  // const missionArray = 'The Universal Church gives a great importance and a prominent role to the youth pastoral ministry, since it constitutes a vital framework for mission and the new evangelization proclamation through the Gospel. Proceeding from the Maronite Patriarchal Synod recommendations, the Youth Pastoral Ministry aims at devoting attention to the Maronite youth pastoral affairs in Lebanon, the Patriarchal scope and the countries of the expansion. It works on promoting and developing the youths’ sense of belonging and commitment to their church and nation through their effective pastoral and social presence. It also seeks to deepen the communion between the youth and Shepherds.';
  
  // const splitTextIntoParts = (text, wordsPerPart) => {
  //   const words = text.split(" ");
  //   let parts = [];
  
  //   for (let i = 0; i < words.length; i += wordsPerPart) {
  //     parts.push(words.slice(i, i + wordsPerPart).join(" "));
  //   }
  
  //   return parts;
  // };
  
  // // Split missionArray into parts dynamically
  // const missionParts = splitTextIntoParts(missionArray, 35);
  //}

  const missionArray = ['The Universal Church gives a great importance and a prominent role to the youth pastoral ministry, since it constitutes a vital framework for mission and the new evangelization proclamation through the Gospel.','Proceeding from the Maronite Patriarchal Synod recommendations, the Youth Pastoral Ministry aims at devoting attention to the Maronite youth pastoral affairs in Lebanon, the Patriarchal scope and the countries of the expansion.','It works on promoting and developing the youths’ sense of belonging and commitment to their church and nation through their effective pastoral and social presence.It also seeks to deepen the communion between the youth and Shepherds.'];


  return (
    <div className='postion-relative'>
    {stories.map((story, index) => {
      let images = [];
      try {
        images = JSON.parse(story.img);
        if (!Array.isArray(images)) {
          throw new Error("Parsed JSON is not an array");
        }
      } catch (e) {
        console.error("Error parsing images JSON:", e);
        images = []; 
      }
return(
    <div className='story_container pb-5 position-relative' key={index}>
          <div className="container py-5">
            <div className="d-flex flex-column align-items-center">
              <Fade>
                <h1 className='text_secondary fw-bold'>Our Story</h1>
              </Fade>
              <hr size='10'/>
            </div>
            
            <div className="story_p row my-5 d-flex flex-row">
                <div className="col-lg-4 col-md-12">
              <Fade>
                  {/* <p style={{fontSize: '1rem'}} className='text-start text-white'>Youth who colored the history With the trust and blessing of His Beatitude Patriarch Mar Bechara Boutros Al-Rai, the Youth Pastoral Ministry worked as a beehive on organizing activities and workshops to achieve its mission, vision and goals. This came following the establishment of strong communication basis with the mission partners in the Youth Committee of the Apostolic Lay Council, Youth Committees in the Maronite eparchies and Apostolic and scouting movements.</p> */}
                  <p style={{fontSize: '1rem'}}  dangerouslySetInnerHTML={{__html:story.description1}} className='text-white'></p>
              </Fade>
                </div>
                <div className="col-lg-4 col-md-12">
              <Fade delay={250}>
                  {/* <p style={{fontSize: '1rem'}} className='text-white'>Today, on the sixth anniversary of the establishment of the Ministry, we stand as if it happened yesterday, to honestly and modestly review and remember the most noticeable events and manifestations, like a flower that spreads its fragrance, or a lamp on the lighthouse. We admit that we are unworthy workers. Glory is to the Lord alone working in us by His Holy Spirit, for the salvation of the Youth souls and their holiness at all times.</p> */}
                  <p style={{fontSize: '1rem'}} className='text-white'>{story.description2}</p>
              </Fade>
                </div>
                <div className="col-lg-4 col-md-12">
              <Fade delay={500}>
                  {/* <p style={{fontSize: '1rem'}} className='text-white'>After this tip of the mission's iceberg, the Youth Pastoral Ministry is heading towards numerous prospects and challenges, to reach the depth, meet the youth, and answer their needs, and to carry the gospel especially to the uncommitted pastorally. This year 2017 is characterized by a unique and a long-awaited event, which is the World Maronite Youth Days that will take place in Lebanon, and by of the preparations at all levels for a youth pastoral renaissance to keep pace with the synod on youth, proclaimed by Pope Francis for 2018.</p> */}
                <p style={{fontSize: '1rem'}} className='text-white'>{story.description3}</p>
              </Fade>
                </div>
            </div>
            {images.map((ima, idx) => {
              const img = ima.img;
              let imagPath=img;
              if (imagPath.includes('../../../assets')) {
                imagPath = imagPath.replace('../../../assets', 'assets');
            } else if (imagPath.includes('../../assets')) {
              imagPath = imagPath.replace('../../assets', 'assets');
            } else if (imagPath.includes('../assets')) {
              imagPath = imagPath.replace('../assets', 'assets');
            } else {
              imagPath = imagPath; 
            }
              const correctedImagePath = `${Ip}${imagPath}`;
            return(  
            <div className="image-container position-relative" key={idx}>
               {idx === 0 && (
                 <Fade>
                <div className='img_content img_content1 position-absolute w-34 bg-white p-3'>
                  <img src={correctedImagePath} className='w-100' alt="" />
                  <p className='mt-3 text-start'>{ima.caption}</p>
                </div>
              </Fade>
               )}
               {idx === 1 && (
                 <Fade delay={600}>
                <div className='img_content img_content2 position-absolute w-34 bg-white p-3'>
                  <img src={correctedImagePath} className='w-100' alt="" />
                  <p className='mt-3 text-start'>{ima.caption}</p>
                </div>
              </Fade>
               )}
               {idx === 2 && (
              <Fade delay={300}>
                <div className='img_content img_content3 position-absolute w-34 bg-white p-3'>
                  <img src={correctedImagePath} className='w-100' alt="" />
                  <p className='mt-3 text-start'>{ima.caption}</p>
                </div>
              </Fade>
              )}
            </div>
    )})}
          </div>
          <div className='w-100 position-absolute bottom-0'>
            <img src={storyBtm} alt="" />
          </div>
      </div>
)
})}
      {/* Mission */}
        <div className="d-flex flex-column align-items-center justify-content-center mission_container bg_secondary" >
          <Fade>
            <h1 className='text-white fw-bold text-uppercase'>Our Mission</h1>
          </Fade>
          <hr size='10'/>
          <div className='text_content d-flex flex-lg-row flex-column justify-content-center align-items-center gap-3 container my-5'>
          <Fade cascade triggerOnce>

          {/* split the paragraph */}
          {/* <div className="d-flex flex-lg-row flex-column justify-content-between container">
            {missionParts.map((part, index) => (
              <div key={index} className='col-lg-4 col-md-12'>
                <p className='text_primary' style={{ fontWeight: '500' }}>{part}</p>
              </div>
            ))}
          </div> */}
          
        
          {missionArray.map((missionItem, index) => (
              <div key={index}>
                <p className='text_primary' style={{fontWeight: '500'}}>{missionItem}</p>
              </div>
            ))
          }
        {/* <div className="d-flex flex-lg-row flex-column justify-content-between container">
          {VM.map((missionItem, index) => (
              <div key={index}>
                <p className='text_primary' style={{fontWeight: '500'}}>{missionItem.mission}</p>
              </div>
            ))
          }
        </div> */}

          </Fade>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-start container mt-5">
        <div className="row">
      {/* Vision */}
          {VM.map((vision, dx) => (
            <div className="d-flex flex-column align-items-center justify-content-center VisMis_container col-md-6" key={dx} style={{borderRight: '1px solid'}}>
              <Fade>
                <h1 className='text_primary fw-bold text-uppercase'>Our Vision</h1>
              </Fade>
              <hr size='10'/>
              <Fade triggerOnce>
                <p className='' dangerouslySetInnerHTML={{__html:vision.vision}}></p>
                {/* <p className='text-start w-75'>Committed youths that grow in the bosom of the church, carrying their message and bearing witness to the evangelical values in their lives; so as to the embodiment of the prophetic teachings of the Saint Pope John Paul II for the youth and the priority given by the Patriarch Mar Bechara Boutros Al-Rai for the youth as a regenerative power in the church and society.</p> */}
              </Fade>
            </div>
          ))}
          {/* Objectives */}
            <div className="d-flex flex-column align-items-center justify-content-center VisMis_container col-md-6">
              <Fade delay={500}>
                <h1 className='text_primary fw-bold text-uppercase'>Our Objective</h1>
              </Fade>
              <hr size='10'/>
          {objectives.map((objective) => (
              <div className='d-flex flex-column align-items-start gap-3 w-75' key={objective.id}>
                <Fade cascade delay={550} triggerOnce>
                  <div className='d-flex flex-row align-items-center gap-2 '>
                  <BiChevronRight style={{ width: '30px', height: '30px', minWidth: '30px', minHeight: '30px', maxWidth: '30px', maxHeight: '30px' }} fill='#2BD59C' />
                  <span className='text-start'>{objective.description}</span>
                  </div>
                </Fade>
              </div>
          ))}
            </div>
        </div>
      </div>
      <AttentionSeeker effect="pulse">
        <div className='w-33 donate_bg position-relative d-flex flex-column align-items-center justify-content-center bottom-0 about_container my-4' style={{left: '33%'}}>
            <img src={BgGreen} alt='' className='w-40 mb-5'/>
            <div className='position-absolute d-flex align-items-center justify-content-center flex-column' style={{top: '32%'}}>
                <h1 className='text_primary fw-bold moon-font' style={{fontSize: '3.5rem'}}>JOIN BKJ</h1>
                <Link className='btn text_primary fw-bold moon-font py-2 join_btn mt-5'>NOW</Link>
            </div>
        </div>
      </AttentionSeeker>
    </div>
  )
}
   
export default Story
