import {React,useEffect,useState} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { FaChevronRight } from "react-icons/fa";
import './VisionMission.css'
import VMBlue from '../../../assets/img/VM_blue.png'
import VMGreen from '../../../assets/img/VM_green.png'
import VMBg from '../../../assets/img/VM_bg.png'
import MissionTxt from '../../../assets/img/mission_text.png'
import VisionTxt from '../../../assets/img/vision_text.png'
import Fade from 'react-awesome-reveal';

const VisionMission = ({Ip}) => {
    const [VM, setVM] = useState([]);
    useEffect(() => {
        fetchVM();
      }, []);
    
    const fetchVM = async () => {
        try {
          const response = await axios.get(`${Ip}Controllers/visionmission.php`, {
            headers: { 'Content-Type': 'application/json' }
          });
          setVM(response.data);
        //   console.log("Fetched Highlights:", response.data);
        } catch (error) {
          console.error('Error fetching Highlights:', error.response ? error.response.data : error.message);
        }
    };
  return (
    <div className='VM_container' style={{background: '#F2F2F2'}}>
    <div className='d-flex flex-column flex-lg-row align-items-baseline vm_body container'>
        <div className="row">
            <div className='col-lg-4 col-md-12 text-center'>
                {VM.map((content, index) => (
                    <Fade Right triggerOnce key={index}>
                        <p dangerouslySetInnerHTML={{__html:content.vision.substring(0,253)+'...'}}></p>
                        <Link to='/About#vision' className='text_primary fw-bold visionMission_btn'>Read More <FaChevronRight size='15' fill='#2be6f1'/></Link>
                    </Fade>
                ))}
            </div>
            <div className='col-lg-4 col-md-12 position-relative MVAnimatedImg' style={{top: '-5rem'}}>
                <Fade cascade triggerOnce>
                    <img src={VMBg} className='position-absolute animationMV1' alt="" />
                    <img src={VMGreen} className='position-absolute animationMV2' alt=""/>
                    <img src={VMBlue} className='position-absolute animationMV3' alt=""/>
                    <img src={MissionTxt} className='position-absolute animationMV4' alt="" />
                    <img src={VisionTxt} className='position-absolute animationMV5' alt="" />
                </Fade>
            </div>
            <div className='col-lg-4 col-md-12 text-center'>
                {VM.map((content, index) => (
                    <Fade right delay={200} triggerOnce key={index}>
                        <p dangerouslySetInnerHTML={{__html:content.mission.substring(0,236)+'...'}}></p>
                        <Link to='/About#mission' className='text_primary fw-bold visionMission_btn'>Read More <FaChevronRight size='15' fill='#2be6f1'/></Link>
                    </Fade>
                ))}
            </div>
        </div>
    </div>
</div>

  )
}

export default VisionMission
