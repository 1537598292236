import React from 'react';
import './NewReleases.css';
import ReleasesArray from './ReleasesArray';
import { FaPlay } from "react-icons/fa";

const Podcasts = () => {
    return (
        <div className='newReleases_container container'>
            <h5 className='text-start text-uppercase'>Podcasts We love</h5>
            <div className="d-flex flex-row align-items-center justify-content-between gap-3 mt-3">
                {ReleasesArray.map((release, index) => (
                    <div
                        className='newReleases_card d-flex flex-column align-items-center newReleases-bg_grey w-20'
                        key={index}
                    >
                        <div className=''>
                            <img src={release.img} alt={release.title} className='newReleases_card_img' />
                        </div>
                        <div className='newReleases_card_hover_content d-flex flex-column align-items-start position-absolute w-100 px-3'>
                            <h6 className='newReleases_card_title text-start fw-bold'>{release.title}</h6>
                        </div>
                        <div className='newReleases_card_content d-flex flex-row align-items-end justify-content-between position-absolute px-3' style={{zIndex: '3'}}>
                            <p className='newReleases_card_author m-0 mt-2 text-start w-75 newReleases_card_desc' style={{ fontSize: '0.8rem' }}>{release.desc}</p>
                            <div className='p-3 d-flex flex-row align-items-center justify-content-center bg_secondary rounded-circle play_btn' style={{cursor: 'pointer'}}>
                                <FaPlay color='#001ad4' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Podcasts;
